import {
  CompiereDataGridFilterModel,
  CompiereDataGridFilterType,
  CompiereDataGridGroupModel,
  CompiereDataGridRequestJSON,
  CompiereDataGridSortModel,
  CompiereDataGridType,
} from '@compiere-ws/models/compiere-data-json';

export enum FilterLink {
  OR = 'or',
  AND = 'and',
}

export interface FilterOperator {
  id: number;
  filterType: CompiereDataGridFilterType;
  label: string;
  type: OperatorFilterType;
  isRange: boolean;
}

export class UniversalFilterWS {
  windowType: CompiereDataGridType;
  entityId: number;
  filterModel: {
    [columnName: string]: CompiereDataGridFilterModel;
  };
  sortModel: CompiereDataGridSortModel[];
  rowGroupCols?: CompiereDataGridGroupModel[];

  constructor(uf: CompiereDataGridRequestJSON) {
    this.windowType = uf.windowType;
    this.entityId = uf.entityId;
    this.filterModel = uf.filterModel;
    this.sortModel = uf.sortModel;
    this.rowGroupCols = uf.rowGroupCols;
  }
}

export enum OperatorFilterType {
  LESS = 'lessThan',
  LESS_EQUALS = 'lessThanOrEqual',
  BIGGER = 'biggerThan',
  BIGGER_EQUALS = 'biggerThanOrEqual',
  BETWEEN = 'between',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  EQUALS = 'equals',
  NOT_EQUALS = 'notEqual',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
}

export const filterOperators: FilterOperator[] = [
  {
    id: 0,
    filterType: CompiereDataGridFilterType.DATE,
    label: '<',
    type: OperatorFilterType.LESS,
    isRange: false,
  },
  {
    id: 1,
    filterType: CompiereDataGridFilterType.DATE,
    label: '<=',
    type: OperatorFilterType.LESS_EQUALS,
    isRange: false,
  },
  {
    id: 2,
    filterType: CompiereDataGridFilterType.DATE,
    label: '>',
    type: OperatorFilterType.BIGGER,
    isRange: false,
  },
  {
    id: 3,
    filterType: CompiereDataGridFilterType.DATE,
    label: '>=',
    type: OperatorFilterType.BIGGER_EQUALS,
    isRange: false,
  },
  {
    id: 4,
    filterType: CompiereDataGridFilterType.DATE,
    label: '!=',
    type: OperatorFilterType.NOT_EQUALS,
    isRange: false,
  },
  {
    id: 5,
    filterType: CompiereDataGridFilterType.DATE,
    label: '=',
    type: OperatorFilterType.EQUALS,
    isRange: false,
  },
  {
    id: 6,
    filterType: CompiereDataGridFilterType.DATE,
    label: 'universalFilter.filterLabels.between',
    type: OperatorFilterType.BETWEEN,
    isRange: true,
  },
  {
    id: 7,
    filterType: CompiereDataGridFilterType.TEXT,
    label: 'universalFilter.filterLabels.contains',
    type: OperatorFilterType.CONTAINS,
    isRange: false,
  },
  {
    id: 8,
    filterType: CompiereDataGridFilterType.TEXT,
    label: 'universalFilter.filterLabels.notContains',
    type: OperatorFilterType.NOT_CONTAINS,
    isRange: false,
  },
  {
    id: 9,
    filterType: CompiereDataGridFilterType.TEXT,
    label: 'universalFilter.filterLabels.startsWith',
    type: OperatorFilterType.STARTS_WITH,
    isRange: false,
  },
  {
    id: 10,
    filterType: CompiereDataGridFilterType.TEXT,
    label: 'universalFilter.filterLabels.endsWith',
    type: OperatorFilterType.ENDS_WITH,
    isRange: false,
  },
  {
    id: 11,
    filterType: CompiereDataGridFilterType.TEXT,
    label: '=',
    type: OperatorFilterType.EQUALS,
    isRange: false,
  },
  {
    id: 12,
    filterType: CompiereDataGridFilterType.TEXT,
    label: '!=',
    type: OperatorFilterType.NOT_EQUALS,
    isRange: false,
  },
  {
    id: 13,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: '=',
    type: OperatorFilterType.EQUALS,
    isRange: false,
  },
  {
    id: 14,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: '!=',
    type: OperatorFilterType.NOT_EQUALS,
    isRange: false,
  },
  {
    id: 15,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: '<',
    type: OperatorFilterType.LESS,
    isRange: false,
  },
  {
    id: 16,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: '<=',
    type: OperatorFilterType.LESS_EQUALS,
    isRange: false,
  },
  {
    id: 17,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: '>',
    type: OperatorFilterType.BIGGER,
    isRange: false,
  },
  {
    id: 18,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: '>=',
    type: OperatorFilterType.BIGGER_EQUALS,
    isRange: false,
  },
  {
    id: 19,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: 'universalFilter.filterLabels.between',
    type: OperatorFilterType.BETWEEN,
    isRange: true,
  },
  {
    id: 20,
    filterType: CompiereDataGridFilterType.SET,
    label: '=',
    type: OperatorFilterType.EQUALS,
    isRange: false,
  },
  {
    id: 21,
    filterType: CompiereDataGridFilterType.SET,
    label: '!=',
    type: OperatorFilterType.NOT_EQUALS,
    isRange: false,
  },
  {
    id: 22,
    filterType: CompiereDataGridFilterType.TEXT,
    label: '<',
    type: OperatorFilterType.LESS,
    isRange: false,
  },
  {
    id: 23,
    filterType: CompiereDataGridFilterType.TEXT,
    label: '<=',
    type: OperatorFilterType.LESS_EQUALS,
    isRange: false,
  },
  {
    id: 24,
    filterType: CompiereDataGridFilterType.TEXT,
    label: '>',
    type: OperatorFilterType.BIGGER,
    isRange: false,
  },
  {
    id: 25,
    filterType: CompiereDataGridFilterType.TEXT,
    label: '>=',
    type: OperatorFilterType.BIGGER_EQUALS,
    isRange: false,
  },
];
