<div
  [style.backgroundColor]="backgroundColorCss"
  [style.display]="displayCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  [pTooltip]="tooltip"
  life="3"
  tooltipPosition="top"
  showDelay="500"
  tooltipZIndex="2100"
  data-cy="input-text-html"
  [attr.data-cy-columnName]="data ? data.columnName : columnName"
>
  <label
    *ngIf="this.isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0"
    [style.font-size]="overridedCSS?.labelFontSize"
    >{{ label | textLimit: this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7 }}</label
  >
  <div #quillHeader [ngClass]="{ 'input-text-html-header-mandatory': data.isMandatory }">
    <!-- desktop -->
    <div [ngStyle]="{ display: isMobile ? 'none' : 'flex' }" class="input-text-html-header">
      <button class="ql-bold" data-quill-action aria-label="Bold">Bold</button>
      <button class="ql-italic" data-quill-action aria-label="Italic">Italic</button>
      <button class="ql-underline" data-quill-action aria-label="Underline">Underline</button>
      <select class="ql-color" data-quill-action></select>
      <select class="ql-background" data-quill-action></select>
      <select class="ql-header" data-quill-action>
        <option value="1" data-quill-action>Heading</option>
        <option value="2" data-quill-action>Subheading</option>
        <option selected data-quill-action>Normal</option>
      </select>
      <select class="ql-font" data-quill-action>
        <option selected data-quill-action>Sans Serif</option>
        <option value="serif" data-quill-action>Serif</option>
        <option value="monospace" data-quill-action>Monospace</option>
      </select>
    </div>
    <!-- mobile -->
    <div [ngStyle]="{ display: isMobile ? 'flex' : 'none' }" class="input-text-html-header-mobile">
      <div class="input-text-html-header-mobile-display" (click)="displayMobileMenu = !displayMobileMenu">
        Menu<span [ngClass]="['fa', displayMobileMenu ? 'fa-chevron-down' : 'fa-chevron-right']" style="margin-left: 5px"></span>
      </div>
      <div [ngStyle]="{ display: displayMobileMenu ? 'flex' : 'none' }" class="input-text-html-header-mobile-menu">
        <div class="input-text-html-header-mobile-menu-fieldset">
          <button class="ql-bold" aria-label="Bold"></button>
          <button class="ql-italic" aria-label="Italic"></button>
          <button class="ql-underline" aria-label="Underline"></button>
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </div>
        <div class="input-text-html-header-mobile-menu-fieldset">
          <select class="ql-header">
            <option value="1">Heading</option>
            <option value="2">Subheading</option>
            <option selected>Normal</option>
          </select>
          <select class="ql-font">
            <option selected>Sans Serif</option>
            <option value="serif">Serif</option>
            <option value="monospace">Monospace</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div
    #quillEditor
    class="input-text-html-body"
    [ngClass]="{ 'input-text-html-body-mandatory': data.isMandatory }"
    data-cy="input-text-html-body"
  ></div>
</div>
