import { Injectable } from '@angular/core';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { CompiereLanguageService } from '@compiere-ws/services/compiere-language/compiere-language.service';
import { AppConfig } from '@iupics-config/app.config';
import { IupicsCookieService, LocalStorageIupics } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageSelectionService {
  languages_isoCode: string[] = [];

  constructor(
    private cookieService: IupicsCookieService,
    private connectorService: SecurityManagerService,
    private languageService: CompiereLanguageService,
    private translate: TranslateService,
    private config: AppConfig
  ) {}

  getLanguages(): Observable<CompiereLanguage[]> {
    return this.languageService.getSystemLanguage();
  }

  initLanguages(): CompiereLanguage {
    this.translate.addLangs(this.languages_isoCode);

    // 1. Initilisation de la langue par défaut par celle du browser
    let initial_language = this.getBrowserLanguage(); //custo peeters #130920
    let defaultLanguage: CompiereLanguage = {
      id: initial_language,
      displayValue: initial_language,
      iso_code: initial_language
    }; //end custo
    // 2. Si un cookie avec la langue existe, on récupère celle-ci
    if (this.cookieService.checkLS(LocalStorageIupics.default_language)) {
      defaultLanguage = <CompiereLanguage>(
        JSON.parse(this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.default_language))
      );
    }
    this.translate.setDefaultLang(defaultLanguage.iso_code);
    this.changeLanguage(defaultLanguage);
    this.connectorService.setIupicsDefaultLanguage(defaultLanguage);
    this.cookieService.setAndEncryptLocalStorage(LocalStorageIupics.default_language, JSON.stringify(defaultLanguage));
    return defaultLanguage;
  }

  changeLanguage(language: CompiereLanguage): boolean {
    this.translate.use(language.iso_code);
    this.connectorService.setIupicsDefaultLanguage(language);
    this.cookieService.setAndEncryptLocalStorage(LocalStorageIupics.default_language, JSON.stringify(language));
    document.documentElement.lang = language.iso_code.split('_').shift();
    Global.resetCachedData();
    return true;
  }

  getCurrentLanguage(): CompiereLanguage {
    if (this.cookieService.checkLS(LocalStorageIupics.default_language)) {
      return JSON.parse(this.cookieService.getDecryptedLocalStorage(LocalStorageIupics.default_language));
    } else if (this.connectorService.getIupicsDefaultLanguage()) {
      return cloneDeep(this.connectorService.getIupicsDefaultLanguage());
    } else if (this.connectorService.getIupicsUserAccount().default_language) {
      return cloneDeep(this.connectorService.getIupicsUserAccount().default_language);
    }
    return undefined;
  }

  getBrowserLanguage(): string { //custo peeters #130920
    let browser_language = navigator.language.replace('-', '_');
    if (browser_language.length < 3) {
      browser_language = browser_language.concat('_' + browser_language.toUpperCase());
    }
    return browser_language;
  } // end custo
}
