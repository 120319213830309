import { ComponentFactoryResolver, ComponentRef, Injectable } from '@angular/core';
import { CompiereDataFieldType } from '@compiere-ws/models/compiere-data-json';
import { CompiereWorkflowService } from '@compiere-ws/services/compiere-workflow/compiere-workflow.service';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { AdditionalInfoUiComponent } from '@iupics-components/standard/layouts/additional-info-ui/additional-info-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsField, IupicsTab } from '@iupics-manager/models/iupics-data';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { cloneDeep } from 'lodash';

@Injectable()
export class WindowFactoryService {
  private editTabSize = 40;
  private max: number;
  private currentTab: EditTabUiComponent;
  private currentProcess: any;

  constructor(
    private resolver: ComponentFactoryResolver,
    private uiCreatorService: UICreatorService,
    private workflowService: CompiereWorkflowService
  ) {}

  newEventHandler(event: IupicsEvent): void {
    if (event.type === IupicsTypeEvent.showGridView) {
      this.uiCreatorService.getActualTab(event.item.tabId).subscribe((tab) => {
        event.item.component = tab.gridView.component;
        event.item.data = tab.gridView.data;
        this.addContainerComponent(event.item, event.item.isCssOnComponent);
        event.item.DOMParentComponent.onChildUpdate(event);
      });
    }
    if (event.type === IupicsTypeEvent.showEditView || event.type === IupicsTypeEvent.showAdditionalInfo) {
      this.uiCreatorService.getTab(event.item.tabId).subscribe((tabs) => {
        let tabsTransformed = [];
        let tabsTransformedAdditional = [];
        const firstChild = tabs[0];
        if (event.type === IupicsTypeEvent.showEditView) {
          tabsTransformed = cloneDeep(tabs.filter((tab) => tab.gridView.data.positionEditTab === 1));
          tabsTransformedAdditional = cloneDeep(tabs.filter((tab) => tab.gridView.data.positionEditTab > 1));
        } else {
          tabsTransformed = cloneDeep(tabs.filter((tab) => tab.gridView.data.positionEditTab > 1));
        }
        const viewSize = this.editSize(tabsTransformed, event.type === IupicsTypeEvent.showEditView) * this.editTabSize;
        const tabsTransformed2 = this.transformADTabtoIupics(
          tabsTransformed,
          viewSize,
          event.type === IupicsTypeEvent.showEditView
        );
        event.item.children = [
          {
            container: event.item.container,
            component: 'GridUiComponent',
            cssClass: 'p-col-12 p-col-nopad',
            children: tabsTransformed2
          }
        ];
        const compRef = this.addContainerComponent(event.item, event.item.isCssOnComponent);
        if (compRef) {
          if (compRef.instance instanceof EditViewUiComponent) {
            let viewSizeAd = null;
            if (tabsTransformedAdditional.length > 0) {
              viewSizeAd = this.editSize(tabsTransformedAdditional, false) * this.editTabSize;
            }
            (<EditViewUiComponent>compRef.instance).additionalInfoWidthExpanded = viewSizeAd;
            (<EditViewUiComponent>compRef.instance).linkedComponents = event.item.linkedComponents;
            (<EditViewUiComponent>compRef.instance).IsReadOnly = tabs[0] ? tabs[0].editView.data.IsReadOnly : false;
            (<EditViewUiComponent>compRef.instance).IsDeleteable = tabs[0] ? tabs[0].editView.data.IsDeleteable : false;
            (<EditViewUiComponent>compRef.instance).IsInsertRecord = tabs[0] ? tabs[0].editView.data.IsInsertRecord : false;
            (<EditViewUiComponent>compRef.instance).table_id = tabs[0] ? tabs[0].editView.data.ADTableID : 0;
            (<EditViewUiComponent>compRef.instance).data = tabs[0] ? tabs[0].editView.data : null;
          }
          // avoid resize of grid on additionnalInfoShow
          if (event.type !== IupicsTypeEvent.showAdditionalInfo) {
            (<AbstractDynamicComponent>compRef.instance).notifierLinkedComponent.next({
              type: IupicsTypeEvent.collapseEvent,
              item: firstChild
            });
          }
        }

        event.item.DOMParentComponent.onChildUpdate(event);
        if (compRef.instance instanceof EditViewUiComponent) {
          event.item.container.updateContainerZone(viewSize);
        }
        event.item.DOMParentComponent.onChildUpdate({
          type: IupicsTypeEvent.addBreadcrumbItem,
          item: {
            container: null,
            tabId: firstChild.tabId,
            data: {
              label: firstChild.editView.data.label
            }
          }
        });
      });
    } else if (event.type === IupicsTypeEvent.removeChildEvent) {
      this.removeComponent(event.item);
      event.item.DOMParentComponent.onChildUpdate(event);
      event.item.container.updateContainerZone(0);
    } else if (event.type === IupicsTypeEvent.showProductAttributeView) {
      const fieldsTransformed: IupicsField[] = cloneDeep(Global.productAttributes_caching.get(event.item.productAttributeId));
      const fieldTransformed2 = this.transformADFieldtoIupics(fieldsTransformed, 1);
      event.item.children = fieldTransformed2;
      const compRef = this.addContainerComponent(event.item, event.item.isCssOnComponent);
    } else if (event.type === IupicsTypeEvent.showProcess) {
      let fieldsTransformed: IupicsField[] = Global.process_Params_caching.get(event.item.tabId).fields;
      fieldsTransformed = cloneDeep(fieldsTransformed);
      fieldsTransformed.forEach((field) => {
        field.data.IsParam = true;
      });
      const fieldTransformed2 = this.transformADFieldtoIupics(fieldsTransformed, 2);
      event.item.children = fieldTransformed2;
      const compRef = this.addContainerComponent(event.item, event.item.isCssOnComponent);
    } else if (event.type === IupicsTypeEvent.showSpecificWindow) {
      this.uiCreatorService.getSpecificWindow(event.item.tabId).subscribe((specificWindow) => {
        (<SpecificWindowUiComponent>event.item.container).buildWindow(specificWindow);
      });
    } else {
      return;
    }
  }

  private addContainerComponent(item: DynamicComponent, isCssOnComponent: boolean = true): ComponentRef<any> {
    const componentFactory = this.resolver.resolveComponentFactory(Global.iupics_components.get(<string>item.component));
    if (item.DOMParentComponent.vcr) {
      let componentRef;
      if (item.component === 'AdditionalInfoUiComponent' && (<EditViewUiComponent>item.DOMParentComponent).additionalInfoVcr) {
        componentRef = (<EditViewUiComponent>item.DOMParentComponent).additionalInfoVcr.createComponent(componentFactory);
        (<EditViewUiComponent>item.DOMParentComponent).additionalInfoComponent = <AdditionalInfoUiComponent>componentRef.instance;
      } else {
        componentRef = item.DOMParentComponent.vcr.createComponent(componentFactory);
      }
      if (item.component === 'GridViewUiComponent' || item.component === 'EditTabUiComponent') {
        (<AbstractDynamicComponent>componentRef.instance).IsReadOnly = item.data.IsReadOnly;
        (<AbstractDynamicComponent>componentRef.instance).IsInsertRecord = item.data.IsInsertRecord;
      }
      if (!item.container) {
        item.container = item.DOMParentComponent.container;
      }
      if ((<AbstractDynamicComponent>item.container).windowType === IupicsMenuType.WINDOW) {
        (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.FIELD;
      } else if ((<AbstractDynamicComponent>item.container).windowType === IupicsMenuType.PROCESS) {
        (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.PROCESS_PARA;
      } else if ((<AbstractDynamicComponent>item.container).windowType === IupicsMenuType.FORM) {
        (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.FORM_ITEM;
      }
      (<AbstractDynamicComponent>componentRef.instance).container = item.container;
      (<AbstractDynamicComponent>componentRef.instance).DOMParentComponent =
        item.DOMParentComponent instanceof AdditionalInfoUiComponent
          ? item.DOMParentComponent.DOMParentComponent
          : item.DOMParentComponent;
      (<AbstractDynamicComponent>componentRef.instance).DOMComponent = componentRef;
      (<AbstractDynamicComponent>componentRef.instance).data = item.data;
      (<AbstractDynamicComponent>componentRef.instance).gridPaginator = item.gridPaginator;
      if (item.tabId) {
        (<AbstractDynamicComponent>componentRef.instance).tabId = item.tabId;
      }

      if (item.gridTabFilter) {
        (<AbstractDynamicComponent>componentRef.instance).gridTabFilter = item.gridTabFilter;
      }
      if (item.initRequest) {
        (<AbstractDynamicComponent>componentRef.instance).initRequest = item.initRequest;
      }
      if (item.gridTabValidator) {
        (<AbstractDynamicComponent>componentRef.instance).gridTabValidator = item.gridTabValidator;
      }

      if (item.zoomInfo) {
        (<AbstractDynamicComponent>componentRef.instance).zoomInfo = item.zoomInfo;
      }
      if (item.parentStore) {
        (<AbstractDynamicComponent>componentRef.instance).parentStore = item.parentStore;
      }
      if (item.parentTab || (item.DOMParentComponent && item.DOMParentComponent.parentTab)) {
        (<AbstractDynamicComponent>componentRef.instance).parentTab = item.parentTab
          ? item.parentTab
          : item.DOMParentComponent.parentTab;
      }
      if (item.zoomTarget) {
        (<AbstractDynamicComponent>componentRef.instance).zoomTarget = item.zoomTarget;
      }
      if (item.parentProcess || (item.DOMParentComponent && item.DOMParentComponent.parentProcess)) {
        (<AbstractDynamicComponent>componentRef.instance).parentProcess = item.parentProcess
          ? item.parentProcess
          : item.DOMParentComponent.parentProcess;
        (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.PROCESS_PARA;
      }
      if (item.zoomTargetData) {
        (<AbstractDynamicComponent>componentRef.instance).zoomTargetData = item.zoomTargetData;
      }
      if (item.linkedComponents) {
        item.linkedComponents.forEach((element) => {
          (<AbstractDynamicComponent>componentRef.instance).addSubscribeOnLinkedComponent(element, componentRef);
        });
      }
      if (isCssOnComponent) {
        componentRef.location.nativeElement.className = item.cssClass;
      } else {
        (<AbstractDynamicComponent>componentRef.instance).cssClass = item.cssClass;
      }
      (<AbstractDynamicComponent>componentRef.instance).componentEmitter.subscribe((event) => {
        this.newEventHandler(event);
      });

      /*additional info */

      item.DOMParentComponent.DOMChildrenComponent.push(<AbstractDynamicComponent>componentRef.instance);
      if (item.children) {
        (<AbstractDynamicComponent>componentRef.instance).children = item.children.filter((child) => child !== undefined);
        (<AbstractDynamicComponent>componentRef.instance).children.forEach(
          (child) => (child.DOMParentComponent = <AbstractDynamicComponent>componentRef.instance)
        );
      }
      return componentRef;
    } else {
      return undefined;
    }
  }

  private removeComponent(item: DynamicComponent): void {
    const componentToRemoved = <AbstractDynamicComponent>item.component;

    const vcrIndex: number = item.DOMParentComponent.vcr.indexOf(componentToRemoved.DOMComponent.hostView);
    const compIndex = item.DOMParentComponent.DOMChildrenComponent.indexOf(componentToRemoved);

    if (vcrIndex === -1) {
      return;
    }

    let length = item.DOMParentComponent.DOMChildrenComponent.length;
    for (let i = compIndex; i < length; i++) {
      item.DOMParentComponent.DOMChildrenComponent[compIndex].onRemoveComponent({
        type: IupicsTypeEvent.removeChildEvent,
        item: {
          container: null,
          tabId: item.DOMParentComponent.DOMChildrenComponent[compIndex].tabId,
          data: {
            label: ''
          }
        }
      });
      item.DOMParentComponent.DOMChildrenComponent.splice(compIndex, 1);
      /*Mise à jour de l'url en cas de suppression d'un élément*/
      if (item.container) {
        item.container.notifyUrlChange();
      }
    }

    length = item.DOMParentComponent.vcr.length;
    for (let i = length - 1; i >= vcrIndex; i--) {
      item.DOMParentComponent.vcr.remove(i);
    }
  }

  private editSize(children: IupicsTab[], isFirst: boolean): number {
    let numbersCol: number[] = [];
    let maxPos = 1;
    const childrenCloned = cloneDeep(children);
    let firstChild = null;
    let maxCols;
    let currentPos;
    if (isFirst) {
      firstChild = childrenCloned.splice(0, 1)[0];
      currentPos = firstChild.editView.data.positionEditTab;
      maxPos = maxPos > firstChild.editView.data.positionEditTab ? maxPos : currentPos;
    }
    childrenCloned.forEach((element) => {
      currentPos = element.gridView.data.positionEditTab;
      maxPos = maxPos > element.gridView.data.positionEditTab ? maxPos : currentPos;
    });
    let colspan;
    numbersCol = firstChild === null ? [] : [firstChild.editView.data.colspan];
    childrenCloned.forEach((element) => {
      colspan = element.gridView.data.colspan;
      numbersCol.push(colspan);
    });
    maxCols = Math.max(...numbersCol);
    return maxCols;
  }
  private transformADTabtoIupics(children: IupicsTab[], bladeSize: number, isMain = true): any[] {
    let className: string;
    const cols = [];
    const maxCols = bladeSize / this.editTabSize;
    const tab = [];
    for (let i = 0; i < maxCols; i++) {
      tab[i] = 0;
    }
    children.forEach((element) => {
      let currentCol;
      let currentColspan;
      let elementView: any;
      if (isMain) {
        currentCol = element.editView.data.positionEditTab;
        currentColspan = element.editView.data.colspan;
        element.editView.children = this.transformADFieldtoIupics(element.editView.children, element.editView.data.nbCol);
        elementView = element.editView;
        isMain = false;
        elementView.tabId = element.tabId;

        /* here we check if the first tab is a tab for a document table such as c_order
         or  c_invoice  ( by checcking if there is adocstatus field)
         */
        const fieldsToRemove = [];
        element.editView.children.forEach((child) => {
          child.children.forEach((rowEdit, index) => {
            if (rowEdit.component === 'RowUiComponent') {
              if (this.initStepperData(rowEdit, element)) {
                fieldsToRemove.push({ arrayParent: child.children, element: rowEdit });
              }
            } else if (rowEdit.component === 'AccordionUiComponent') {
              rowEdit.children.forEach((rowAccordion) => {
                rowAccordion.children.forEach((fieldByRow, indexChild) => {
                  if (this.initStepperData(fieldByRow, element)) {
                    fieldsToRemove.push({ arrayParent: rowAccordion.children, element: fieldByRow });
                  }
                });
              });
            } else {
              if (rowEdit !== undefined && rowEdit.data !== undefined && rowEdit.data.columnName !== undefined) {
                if (this.initStepperData(rowEdit, element)) {
                  fieldsToRemove.push({ arrayParent: child.children, element: rowEdit });
                }
              }
            }
          });
          if (
            element &&
            element.editView &&
            element.editView.data &&
            element.editView.data.stepperData &&
            element.editView.data.stepperData['docActionField'] &&
            element.editView.data.stepperData['docStatusField']
          ) {
            element.editView.data.isDocumentTab = true;
          }
        });

        fieldsToRemove.forEach((field) => {
          field.arrayParent.splice(
            field.arrayParent.findIndex((item) => item === field.element),
            1
          );
        });
      } else {
        if (element.isSingleRow) {
          currentCol = 1; // element.editView.data.positionEditTab;
          currentColspan = element.editView.data.colspan;
          element.editView.children = this.transformADFieldtoIupics(element.editView.children, element.editView.data.nbCol);
          elementView = element.editView;
          elementView.tabId = element.tabId;
        } else {
          currentCol = 1; // element.gridView.data.positionEditTab;
          currentColspan = element.gridView.data.colspan;
          element.gridView.tabId = element.tabId;
          elementView = {
            children: [element.gridView],
            component: 'EditTabUiComponent',
            data: {
              positionEditTab: 1,
              colspan: 2,
              isCollapsable: element.gridView.data.isCollapsable,
              isCollapsedDefault: element.gridView.data.isCollapsedDefault,
              IsDeleteable: element.gridView.data.IsDeleteable,
              label: element.gridView.data.label,
              IsReadOnly: element.gridView.data.IsReadOnly,
              ReadOnlyLogic: element.gridView.data.ReadOnlyLogic,
              TableName: element.gridView.data.TableName
            },
            tabId: element.tabId
          };
        }
      }
      let somme = 0;
      for (let i = 0; i < tab.length; i++) {
        somme += tab[i];
      }
      if (tab[currentCol - 1] === 0 && somme + currentColspan <= maxCols) {
        tab[currentCol - 1] = currentColspan;
        className = 'p-col-' + (12 / maxCols) * currentColspan + ' p-col-nopad';
        cols[currentCol - 1] = {
          cssClass: className,
          component: 'GridUiComponent',
          children: [elementView]
        };
      } else if (tab[currentCol - 1] === currentColspan) {
        cols[currentCol - 1].children.push({
          cssClass: 'p-col-12 p-col-nopad',
          component: 'GridUiComponent',
          children: [elementView]
        });
      } else {
        let isAdd = false;
        let i = currentCol - 1;
        while (isAdd === false && i > -1) {
          if (tab[i] > currentColspan) {
            cols[i].children.push({
              cssClass:
                'p-col-' +
                (12 / tab[i]) * currentColspan +
                ' p-col-nopad p-offset-' +
                (12 / tab[i]) * currentColspan * (currentCol - (i + 1)),
              component: 'GridUiComponent',
              children: [elementView]
            });
            isAdd = true;
          }
          i--;
        }
        if (!isAdd) {
          for (i = 0; i < maxCols; i++) {
            tab[i] = 0;
          }
          tab[currentCol - 1] = currentColspan;
          className = 'p-col-' + (12 / maxCols) * currentColspan + ' p-col-nopad';
          cols.push({
            cssClass: className,
            component: 'GridUiComponent',
            children: [elementView]
          });
        }
      }
    });

    return cols;
  }

  private transformADFieldtoIupics(children: IupicsField[], nbCol: number): any[] {
    let currentRow = 0;
    let newRow = true;
    const fields = [];
    let fieldsCol = [];
    for (let i = 0; i < nbCol; i++) {
      fieldsCol.push(0);
    }
    let className: string;
    for (let i = 0; i < children.length; i++) {
      if (children[i].component === 'ProgressBarUiComponent' || children[i].component === 'GridViewUiComponent') {
        return children;
      } else {
        if (children[i].component === 'AccordionUiComponent') {
          children[i].children = this.transformADFieldtoIupics(children[i].children, nbCol);
        }

        const currentPos = children[i].data.nbCol;
        const currentColspan = children[i].data.colspan;
        if (!newRow) {
          for (let j = currentPos - 1; j < fieldsCol.length; j++) {
            if (fieldsCol[j]) {
              newRow = true;
            }
          }
        }

        if (newRow) {
          fieldsCol = [];
          for (let m = 0; m < nbCol; m++) {
            fieldsCol.push(0);
          }
          let countOffest = 0;
          let finish = false;
          let k = currentPos - 2;
          while (k > -1 && !finish) {
            if (!fieldsCol[k--]) {
              countOffest++;
            } else {
              finish = true;
            }
          }
          currentRow++;
          className = 'p-col-' + (12 / nbCol) * currentColspan + ' p-offset-' + countOffest * (12 / nbCol);
          if (children[i] && children[i].isRange) {
            className += ' rangeFrom';
          }
          children[i].cssClass = className;
          fields[currentRow] = {
            cssClass: '',
            component: 'RowUiComponent',
            children: [children[i]]
          };
          newRow = false;
        } else {
          let countOffest = 0;
          let finish = false;
          let k = currentPos - 2;
          while (k > -1 && !finish) {
            if (!fieldsCol[k--]) {
              countOffest++;
            } else {
              finish = true;
            }
          }
          children[i].cssClass = 'p-col-' + (12 / nbCol) * currentColspan + ' p-offset-' + countOffest * (12 / nbCol);
          if (children[i].data && children[i].data.fromRange) {
            children[i].cssClass += ' rangeTo';
          }
          fields[currentRow].children.push(children[i]);
        }
        for (let n = currentPos - 1; n < currentPos - 1 + currentColspan; n++) {
          fieldsCol[n] = 1;
        }
      }
    }
    return fields;
  }

  initStepperData(field: IupicsField, tab: IupicsTab): boolean {
    if (field.data.columnName === 'DocAction') {
      tab.editView.data.stepperData['docActionField'] = field;
      this.workflowService.getAvailaibleStatus(field.fieldId).subscribe((statusList) => {
        if (statusList !== undefined) {
          tab.editView.data.workflowStatus = statusList;
          tab.editView.data.workflowStatus.AD_Process_ID = field.processId;
        }
      });
      return true;
    } else if (field.data.columnName === 'DocStatus') {
      tab.editView.data.stepperData['docStatusField'] = field;
      return true;
    }
    return false;
  }
}
