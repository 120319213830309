import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CompiereLanguage } from '@compiere-ws/models/compiere-language-json';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { ProcessParams } from '@iupics-components/specific/window/process-ui/process-ui.component';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { InputSwitchUiComponent } from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';
import { InputTextUiComponent } from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { NotificationManagerService } from '@iupics-manager/managers/notification-manager/notification-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { RoleUI, UserAccount } from '@login-page/models/user-account.';
import { TranslateService } from '@ngx-translate/core';
import { LanguageSelectionService } from '@web-desktop/components/menu-top/controllers/language-selection/language-selection.service';
import { ThemeService } from '@web-desktop/controllers/theme.service';
import { UserPreference } from '@web-desktop/models/user-preference';
import * as moment from 'moment';
import { OverlayPanel } from 'primeng/overlaypanel';
import { of, Subscription } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as shajs from 'sha.js';
@Component({
  selector: 'iu-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  languages: { items: CompiereLanguage[] } = { items: [] };
  printerNames = { items: [] };
  opened = false;
  roleSelected: RoleUI;
  isUpdateProfil = false;
  isFirstShow = false;
  currentLanguage: CompiereLanguage;
  previousLanguage: CompiereLanguage;
  currentDefaultDate: Date;
  subscriptions: any[] = [];
  @Output()
  logoutEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  changeRoleEmitter: EventEmitter<any> = new EventEmitter<any>();
  // @Output()
  // changePreviousRoleEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  openEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  nbTabs = 0;
  @Input() overlayPanel: UserProfileOverlayPanel;

  isPrintPreview: boolean;
  isShowAcct: boolean;
  isShowAdvanced: boolean;
  isShowTrl: boolean;
  isAutoCommit: boolean;
  currentUITheme;
  UIThemes: { items: {}[] };
  NotificationDelay: { items: {}[] } = {
    items: [
      { displayValue: '2', id: 2 },
      { displayValue: '5', id: 5 },
      { displayValue: '10', id: 10 },
      { displayValue: '15', id: 15 },
      { displayValue: '20', id: 20 },
      { displayValue: '30', id: 30 },
    ],
  };
  printerName: Object;
  notificationReminderTimeout: any;
  valSubscr: Subscription;
  canSubscr: Subscription;
  @ViewChild('languagesSelector')
  set languageSelection(languagesSelector: AutocompleteUiComponent) {
    if (languagesSelector) {
      if (this.languageSelectionService.getCurrentLanguage() !== undefined) {
        this.currentLanguage =
          this.languageSelectionService.getCurrentLanguage();
        languagesSelector.selectByIdentifier(
          'iso_code',
          this.currentLanguage.iso_code
        );
        this.previousLanguage = this.currentLanguage;
      }
    }
  }

  @ViewChild('languagesSelector')
  languagesSelector: AutocompleteUiComponent;

  @ViewChild('themeSelector')
  themeSelector: AutocompleteUiComponent;

  @ViewChild('printerNameField')
  printerNameField: InputTextUiComponent;

  @ViewChild('autoCommitField')
  autoCommitField: InputSwitchUiComponent;

  @ViewChild('advancedShowField')
  advancedShowField: InputSwitchUiComponent;

  @ViewChild('acctShowField')
  acctShowField: InputSwitchUiComponent;

  @ViewChild('trlShowField')
  trlShowField: InputSwitchUiComponent;

  @ViewChild('previewField')
  previewField: InputSwitchUiComponent;

  @ViewChild('roleList')
  roleList: ElementRef<HTMLLIElement>;
  @ViewChild('notificationReminderTimeoutField')
  notificationReminderTimeoutField: AutocompleteUiComponent;

  rolesIsDisplay = false;

  currentUserAccount: UserAccount;
  isMobile = false;
  isMobileWidth = false;

  constructor(
    private uiCreatorService: UICreatorService,
    public connectorService: SecurityManagerService,
    private translateService: TranslateService,
    private languageSelectionService: LanguageSelectionService,
    private socketService: SocketService,
    private messageManager: MessageManagerService,
    private config: AppConfig,
    private notificationManager: NotificationManagerService,
    private themeService: ThemeService,
    private progressService: ProcessInProgressService
  ) {}

  ngOnInit() {
    this.isMobile = Global.isMobile();
    this.isMobileWidth = Global.isMobileWidth();
    this.currentDefaultDate = moment(
      this.connectorService.getIupicsUserContext['#Date']
    ).toDate();
    this.isFirstShow = Global.isLoginPageShow;
    this.opened = Global.isLoginPageShow;
    this.currentUserAccount = this.connectorService.getIupicsUserAccount();
    this.selectCurrentRole();
    this.languageSelectionService.getLanguages().subscribe((languages) => {
      this.languages.items = languages;
    });
    this.uiCreatorService.getCupsPrinters().subscribe((cups) => {
      cups.forEach((printer) => {
        this.printerNames.items.push({
          id: printer.name,
          displayValue: printer.name,
        });
      });
    });
    this.socketService.initSocket();
    // ? Pour faire des recharger config sans ennuyer ceux sur apiz-dev
    // ? et ceux en localhost, decommenté cette ligne et remplacer le trigramme
    // SocketService.RELOAD_CONFIG_CHANNEL += '_private_xmz';

    (async () => {
      for await (const reload of this.socketService.enableReloadConfigChannel()) {
        if (reload) {
          let timer = this.config.getConstant('reloadingTimeout');
          timer = this.displayReloading(timer);
          setInterval(() => {
            timer = this.displayReloading(timer);
          }, 1000);
        }
      }
    })();

    this.overlayPanel.overridedHide = this.overlayPanel.hide;
    this.overlayPanel.hide = () => {
      if (!this.isUpdateProfil) {
        this.overlayPanel.overridedHide();
      }
    };

    this.overlayPanel.onHide.subscribe((_) => {
      this.isUpdateProfil = false;
      if (this.rolesIsDisplay) {
        this.roleList.nativeElement.classList.remove('opened');
        this.roleList.nativeElement.classList.add('closed');
        this.rolesIsDisplay = false;
      }
    });
  }

  private displayReloading(timer: number) {
    this.messageManager.newMessage(
      new IupicsMessage(
        this.translateService.instant('generic.warning'),
        this.translateService.instant('userProfile.refreshSession', {
          timer: timer--,
        }),
        'message'
      )
    );
    if (timer <= 0) {
      // tslint:disable-next-line: deprecation
      location.reload(true); // mis à true pour prendre les derniere modifs coté serveur et non plus du cache
    }
    return timer;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.socketService.disableReloadConfigChannel();
  }

  selectCurrentRole(roleSelected?: RoleUI) {
    if (!roleSelected) {
      roleSelected = this.currentUserAccount.current_role;
    } else {
      const previousRole = this.currentUserAccount.roles.find(
        (r) => r.isSelected
      ).role_id;
      // on désactive le channel du role précédent dans le centre des notifications et on active le nouveau
      this.notificationManager.changeRoleChannel(
        previousRole,
        roleSelected.role_id
      );
    }

    for (let i = 0; i < this.currentUserAccount.roles.length; i++) {
      this.currentUserAccount.roles[i].isSelected =
        this.currentUserAccount.roles[i].role_id === roleSelected.role_id;
      if (this.currentUserAccount.roles[i].isSelected) {
        this.roleSelected = this.currentUserAccount.roles[i];
      }
    }
  }

  display() {
    this.currentUserAccount = this.connectorService.getIupicsUserAccount();
    this.selectCurrentRole();
    this.opened = true;
  }

  hide() {
    this.opened = false;
    this.isUpdateProfil = false;
    this.isFirstShow = false;
    if (this.rolesIsDisplay) {
      this.roleList.nativeElement.classList.remove('opened');
      this.roleList.nativeElement.classList.add('closed');
      this.rolesIsDisplay = false;
    }
    this.overlayPanel.hide();
  }

  logout(event) {
    this.uiCreatorService.resetCachingData();
    Global.resetCachedData();
    this.logoutEmitter.emit(event);
  }

  valideRole() {
    this.subscriptions.push(
      this.connectorService
        .changeRole(this.roleSelected)
        .pipe(switchMap((userAccount) => this.connectorService.getCtx()))
        .subscribe((iupicsUserAccount) => {
          this.changeRoleEmitter.emit(this.roleSelected);
          this.notificationManager.roleChanged();
          this.progressService.updateSocket();
        })
    );
  }

  changeRole(roleSelected: RoleUI) {
    if (roleSelected.role_id !== this.roleSelected.role_id) {
      if (this.nbTabs > 0) {
        if (
          (this.valSubscr && !this.valSubscr.closed) ||
          (this.canSubscr && !this.canSubscr.closed)
        ) {
          Global.infoDialog.cancelDialog();
        }
        Global.infoDialog.message = {
          summary: this.translateService.instant(
            'infodialog.dialogs.changerole.title'
          ),
          detail: this.translateService.instant(
            'infodialog.dialogs.changerole.message'
          ),
        };
        Global.infoDialog.dialogType = InfoDialogType.CONFIRM;
        Global.infoDialog.showInfoDialog();
        this.valSubscr = Global.infoDialog.confirm.subscribe(() => {
          this.selectCurrentRole(roleSelected);
          this.valideRole();
          this.valSubscr.unsubscribe();
          this.canSubscr.unsubscribe();
        });

        this.canSubscr = Global.infoDialog.cancel.subscribe(() => {
          this.canSubscr.unsubscribe();
          this.valSubscr.unsubscribe();
        });
      } else {
        this.selectCurrentRole(roleSelected);
        this.valideRole();
        this.hide();
      }
    }
  }

  validateProfile() {
    this.previousLanguage = this.currentLanguage;
    this.currentLanguage = this.languagesSelector.fieldValue;

    if (
      this.currentLanguage.iso_code !==
      this.connectorService.getIupicsUserAccount().default_language.iso_code
    ) {
      this.connectorService.getIupicsUserAccount().default_language =
        this.currentLanguage;
      if (this.nbTabs > 0) {
        Global.infoDialog.message = {
          summary: this.translateService.instant(
            'infodialog.dialogs.changelanguage.title'
          ),
          detail: this.translateService.instant(
            'infodialog.dialogs.changelanguage.message'
          ),
        };
        Global.infoDialog.dialogType = InfoDialogType.CONFIRM;
        Global.infoDialog.showInfoDialog();

        const valSubscr = Global.infoDialog.confirm.subscribe(() => {
          this.languageSelectionService.changeLanguage(this.currentLanguage);
          this.savePreference(true);
          this.hide();
          valSubscr.unsubscribe();
          canSubscr.unsubscribe();
        });

        const canSubscr = Global.infoDialog.cancel.subscribe(() => {
          this.currentLanguage = this.previousLanguage;
          this.connectorService.getIupicsUserAccount().default_language =
            this.currentLanguage;
          canSubscr.unsubscribe();
          valSubscr.unsubscribe();
        });
      } else {
        this.languageSelectionService.changeLanguage(this.currentLanguage);
        this.savePreference(true);
        this.hide();
      }
    } else {
      this.savePreference();
      this.hide();
    }
  }

  private savePreference(validateRole = false) {
    let userPref: UserPreference;
    userPref = {
      '#UITheme': this.themeSelector.fieldValue
        ? this.themeSelector.fieldValue.displayValue
        : this.currentUITheme.displayValue,
      PrinterName: this.printerNameField.fieldValue
        ? this.printerNameField.fieldValue.id
          ? this.printerNameField.fieldValue.id
          : null
        : null,
      AutoCommit: this.autoCommitField.fieldValue === 'Y',
      '#ShowAdvanced': this.advancedShowField.fieldValue === 'Y',
      '#ShowAcct': this.acctShowField.fieldValue === 'Y',
      '#ShowTrl': this.trlShowField.fieldValue === 'Y',
      '#PrintPreview': this.previewField.fieldValue === 'Y',
      NotificationReminderTimeout: this.notificationReminderTimeoutField
        .fieldValue
        ? this.notificationReminderTimeoutField.fieldValue.id
        : null,
    };

    const prefSubsc = this.connectorService
      .savePref(userPref)
      .subscribe((res) => {
        if (res) {
          const ctx = this.connectorService.getIupicsUserContext();
          ctx['AutoCommit'] = userPref.AutoCommit ? 'Y' : 'N';
          ctx['#PrintPreview'] = userPref['#PrintPreview'] ? 'Y' : 'N';
          ctx['#ShowAcct'] = userPref['#ShowAcct'] ? 'Y' : 'N';
          ctx['#ShowAdvanced'] = userPref['#ShowAdvanced'] ? 'Y' : 'N';
          ctx['#ShowTrl'] = userPref['#ShowTrl'] ? 'Y' : 'N';
          ctx['#Printer'] = userPref['PrinterName'];
          ctx['#UITheme'] = userPref['#UITheme'];
          ctx['NotificationReminderTimeout'] =
            userPref.NotificationReminderTimeout;
          this.isUpdateProfil = false;
          if (validateRole) {
            this.valideRole();
          }
          prefSubsc.unsubscribe();
        }
      });
  }

  displayRoles() {
    if (this.rolesIsDisplay) {
      this.roleList.nativeElement.classList.remove('opened');
      this.roleList.nativeElement.classList.add('closed');
    } else {
      if (this.isUpdateProfil) {
        this.isUpdateProfil = false;
      }
      this.roleList.nativeElement.classList.remove('closed');
      this.roleList.nativeElement.classList.add('opened');
    }
    this.rolesIsDisplay = !this.rolesIsDisplay;
  }

  reloadConfiguration(event: MouseEvent) {
    event.stopPropagation();
    if (this.roleSelected.role_id === 0) {
      this.connectorService.resetCache().subscribe((res) => {
        this.socketService.broadcast(SocketService.RELOAD_CONFIG_CHANNEL, true);
      });
    }
  }

  openUpdateProfil(event: Event) {
    if (
      this.rolesIsDisplay ||
      this.roleList.nativeElement.classList.contains('opened')
    ) {
      this.roleList.nativeElement.classList.remove('opened');
      this.roleList.nativeElement.classList.add('closed');
      this.rolesIsDisplay = false;
    }
    const ctx = this.connectorService.getIupicsUserContext();
    this.isAutoCommit = ctx['AutoCommit'];
    this.isPrintPreview = ctx['#PrintPreview'];
    this.isShowAcct = ctx['#ShowAcct'];
    this.isShowAdvanced = ctx['#ShowAdvanced'];
    this.isShowTrl = ctx['#ShowTrl'];
    this.printerName = { id: ctx['#Printer'], displayValue: ctx['#Printer'] };
    let timeout = ctx['NotificationReminderTimeout'];
    if (!timeout) {
      timeout = this.config.getConstant('notificationReminderTimeout');
    }
    this.notificationReminderTimeout = {
      id: timeout,
      displayValue: timeout + '',
    };

    this.UIThemes = this.themeService.getAvailableThemes();
    this.themeService.setActiveTheme(ctx['#UITheme']);
    this.currentUITheme = this.themeService.getActiveTheme();

    this.isUpdateProfil = true;

    event.stopPropagation();
  }

  changeDefaultDate(date: Date) {
    if (date) {
      this.connectorService.setIupicsUserContextDate(date);
      this.currentDefaultDate = date;
    }
  }

  loginAs() {
    this.hide();
    Global.isLoginAsModalVisible = true;
  }

  changeTheme(event) {
    if (event && event['displayValue']) {
      this.themeService.setActiveTheme(event['displayValue']);
    }
    this.currentUITheme = this.themeService.getActiveTheme();
    this.themeSelector.autoComplete.value = this.currentUITheme;
    this.themeSelector.autoComplete.updateInputField();
  }

  showUserContext(event) {
    event.stopPropagation();
    Global.workspace.showContextPanel(
      this.connectorService.getIupicsUserContext()
    );
  }

  resetDashboard(e: Event) {
    e.stopPropagation();
    const ad_process_id = this.config.getConstant(
      'UserProfileComponent#reset_dashboard_process_id'
    );
    const AD_User_ID = this.currentUserAccount.id;
    const AD_Role_ID = this.currentUserAccount.current_role.role_id;
    const channel_id = shajs('sha256')
      .update(this.currentUserAccount.session_id + '_' + moment().valueOf())
      .digest('hex');
    const processParams: ProcessParams = {
      ad_process_id,
      ad_tab_id: null,
      className: null,
      params: { AD_User_ID, AD_Role_ID, channel_id },
      record_id: null,
      tableName: null,
      tables: null,
      windowCtx: this.connectorService.getIupicsUserContext(),
    };
    let isProcessing = true;
    const sub = this.uiCreatorService
      .executeProcess(processParams)
      .pipe(
        map(() => 'Y'),
        catchError(() => of(undefined))
      )
      .subscribe((res) => {
        if (res) {
          const progressSub = this.progressService
            .watchProcessInProgress()
            .subscribe((pings) => {
              const me = this.connectorService.getIupicsUserAccount();
              const ping = pings.find((p) => {
                return (
                  p.AD_User_ID.id === me.id &&
                  p.AD_Process_ID.id === ad_process_id
                );
              });
              if (ping && ping.Status === 'finish') {
                isProcessing = false;
                this.socketService.closeDataChannel(channel_id);
                progressSub.unsubscribe();
                sub.unsubscribe();
                // tslint:disable-next-line: deprecation
                location.reload(true);
              }
            });
        }
      });
  }
}

interface UserProfileOverlayPanel extends OverlayPanel {
  overridedHide: () => any;
}
