import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpResponse } from '@angular/common/http';
import { CompiereDataGridFilterType, CompiereDataGridType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { InfoDialogComponent } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { IupicsField } from '@iupics-manager/models/iupics-data';
import { NotificationCenterUiComponent } from '@web-desktop/components/menu-top/components/notification-center-ui/notification-center-ui.component';
import { WorkspaceComponent } from '@web-desktop/components/workspace/components/workspace-ui/workspace-ui.component';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export enum DeviceEnum {
  ANDROID = 'android',
  IPAD = 'ipad',
  WORKSTATION = 'workstation'
}

export enum IupicsGenericComponent {}

export enum NavigatorEnum {
  MOZILLA,
  CHROME
}

export class Global {
  static device = navigator.userAgent.toUpperCase().includes('ANDROID')
    ? DeviceEnum.ANDROID
    : navigator.userAgent.toUpperCase().includes('IPAD')
    ? DeviceEnum.IPAD
    : DeviceEnum.WORKSTATION;

  static timestackDebug;

  static activeXHRRequest = 0;

  static draggedComponent: any = null;
  static isLoginPageShow = false;

  static ziMax = 1000;

  static defaultTitle = 'Apiz';

  static workspace: WorkspaceComponent;
  static notificationCenter: NotificationCenterUiComponent;

  static process_Params_caching: Map<number, any> = new Map();

  static productAttributes_caching: Map<string, IupicsField[]> = new Map();

  static iupics_components: Map<string, any> = new Map();

  static iupics_specific_window: Map<string, any> = new Map();

  static iupics_widgets: Map<string, any> = new Map();

  static infoDialog: InfoDialogComponent;

  static isLoginAsModalVisible = false;

  private static currencyCache: Map<number, {}> = new Map();

  static lastActivity: Date;
  static mergeLevel: number;
  static configUrl: string;
  static dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';

  static overlayAnimationAutocomplete: any[] = [
    trigger('overlayAnimationAutocomplete', [
      state(
        'void',
        style({
          transform: 'translateY(5%)',
          opacity: 0
        })
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1
        })
      ),
      transition('void => visible', animate('225ms ease-out')),
      transition('visible => void', animate('195ms ease-in'))
    ])
  ];

  static overlayAnimationCalendar: any[] = [
    trigger('overlayAnimationCalendar', [
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1
        })
      ),
      state(
        'visibleTouchUI',
        style({
          transform: 'translate(-50%,-50%)',
          opacity: 1
        })
      ),
      transition('void => visible', [style({ transform: 'translateY(5%)', opacity: 0 }), animate('{{showTransitionParams}}')]),
      transition('visible => void', [
        animate(
          '{{hideTransitionParams}}',
          style({
            opacity: 0,
            transform: 'translateY(5%)'
          })
        )
      ]),
      transition('void => visibleTouchUI', [
        style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)' }),
        animate('{{showTransitionParams}}')
      ]),
      transition('visibleTouchUI => void', [
        animate(
          '{{hideTransitionParams}}',
          style({
            opacity: 0,
            transform: 'translate3d(-50%, -40%, 0) scale(0.9)'
          })
        )
      ])
    ])
  ];

  static resetCachedData() {
    Global.ziMax = 1000;
    Global.productAttributes_caching = new Map();
    Global.process_Params_caching = new Map();
    this.currencyCache = new Map();
  }
  static isLandscape() {
    const mql = window.matchMedia('(orientation: portrait)');
    return !mql.matches;
  }
  static getDeviceWidth() {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    return iOS ? screen.width : window.innerWidth;
  }
  static getDeviceHeight() {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    return iOS ? screen.height : window.innerHeight;
  }
  static isMobile() {
    // (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
    if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
      return true;
    }
    if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i)) {
      return true;
    } else {
      let check = false;
      (function (a) {
        // http://detectmobilebrowsers.com/
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        ) {
          check = true;
        }
      })(navigator.userAgent || navigator.vendor);
      return check;
    }
  }

  static isMobileWidth() {
    return document.documentElement.clientWidth <= 640;
  }

  static downloadFile(response: HttpResponse<any>, defaultFilename: string = 'iupics-downloaded-file') {
    let filename: string;
    // console.log(response.headers.get('content-length'));
    // todo: comprendre pourquoi response.headers.get('content-length') renvoi null, alors que dans l'onglet network il est là
    // if (parseInt(response.headers.get('content-length'), 10) > 0) {
    if (response.headers.get('Content-Disposition')) {
      filename = response.headers.get('Content-Disposition').match('filename="(.*)"(;|$)')[1];
    } else if (response.headers.get('content-disposition')) {
      filename = response.headers.get('content-disposition').match('filename="(.*)"(;|$)')[1];
    } else {
      // todo Change this name
      filename = defaultFilename;
    }
    const element = document.createElement('a');
    element.href = window.URL.createObjectURL(response.body);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    // }
  }

  static isActiveRequest(): boolean {
    return Global.activeXHRRequest > 0;
  }

  /**
   * Permet de récupérer la valeur d'un attribut grâce au chemin spécifié dans un tableau de string
   * @param parent
   * @param paths
   */
  static getValueByPath(parent: any, paths: string[]): any {
    let response = Object.assign({}, parent);
    paths.forEach((path) => (response = response[path]));
    return response;
  }

  static getCurrencySymbol(id: number, store: DataStoreService, ad_language: string): Observable<{}> {
    if (this.currencyCache.has(id) && this.currencyCache.get(id) !== undefined) {
      return of(this.currencyCache.get(id));
    } else {
      const currencyRequest: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          windowType: CompiereDataGridType.TABLE,
          ad_language: ad_language,
          startRow: 0,
          endRow: 0,
          tableName: 'C_Currency',
          filterModel: {
            C_Currency_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [id],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };

      return store.getDataGrid(currencyRequest).pipe(
        switchMap((cur) => {
          this.currencyCache.set(id, cur.data[0]);
          return of(this.currencyCache.get(id));
        })
      );
    }
  }
}
