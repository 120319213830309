import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AppVersionService,
  WsVersion,
} from '@compiere-ws/services/app-versions/app-versions.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'iu-app-version-panel',
  templateUrl: './app-version-panel.component.html',
  styleUrls: ['./app-version-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppVersionPanelComponent implements OnInit {
  officialApizWebSiteUrl = `${environment.webSiteUrl}`;
  version =
    environment.CUST_version !== '{CUST_version}'
      ? `${environment.CUST_version} (v${environment.STD_version})`
      : `v${environment.STD_version}`;
  @Input()
  roleId: any;

  wsList: WsVersion[];
  cols: any[];
  isShown = false;
  constructor(
    private appVersionService: AppVersionService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}
  showPanel(event) {
    this.isShown = true;
    this.appVersionService.getAppVersions().subscribe((rep) => {
      this.wsList = rep;
    });
    this.cols = [
      {
        field: 'name',
        header: this.translateService.instant('profil.appVersionPanel.name'),
      },
      {
        field: 'replicas',
        header: this.translateService.instant(
          'profil.appVersionPanel.replicas'
        ),
      },
      {
        field: 'version',
        header: this.translateService.instant('profil.appVersionPanel.version'),
      },
    ];
    event.stopPropagation();
  }
}
