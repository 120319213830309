import { CellEditingStoppedEvent, RowNode } from '@ag-grid-enterprise/all-modules';
import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ProcessPingInfo } from '@compiere-ws/models/process-ping-info';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

export enum EditableCell {
  AppliedAmt = 'AppliedAmt',
  WriteOffAmt = 'WriteOffAmt',
  DiscountAmt = 'DiscountAmt'
}
export enum AdForm {
  FormDetailID = 'AD_FormDetail_ID',
  MultiCurrency = 'MultiCurrency'
}

@Component({
  selector: 'iu-payment-allocation-window-ui',
  templateUrl: './payment-allocation-window-ui.component.html',
  styleUrls: ['./payment-allocation-window-ui.component.scss']
})
export class PaymentAllocationWindowUiComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  @ViewChild('left', { read: ViewContainerRef, static: true }) vcrLeft: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true }) vcrMiddle: ViewContainerRef;
  @ViewChild('right', { read: ViewContainerRef, static: true }) vcrRight: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true }) vcrBot: ViewContainerRef;

  private paymentGridID: number;
  private invoiceGridID: number;
  private sumOfPayments = 0;
  private sumOfInvoices = 0;
  private isDateModified = false;

  constructor(
    protected windowFactory: WindowFactoryService,
    protected resolver: ComponentFactoryResolver,
    protected uiCreator: UICreatorService,
    protected store: DataStoreService,
    protected processService: CompiereProcessService,
    protected socketService: SocketService,
    protected connectorService: SecurityManagerService,
    protected translator: TranslateService,
    private messageManager: MessageManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translator
    );

    this.customDesignArray.push(
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrMiddle',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_Org_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrRight',
        type: CustomDesignItemType.FIELD,
        columnName: 'Currency_To_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'MultiCurrency',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrMiddle',
        type: CustomDesignItemType.FIELD,
        columnName: 'IsSOTrx',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.GRID,
        tableName: 'Affectation paiements Table_Paiement',
        cssClass: 'p-col-12',
        shouldSelectFirst: false,
        isFitResize: true
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.GRID,
        tableName: 'Affectation paiements Table_Facture',
        cssClass: 'p-col-12',
        shouldSelectFirst: false,
        isFitResize: true
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'DifferenceAmt',
        cssClass: 'p-col-12 p-md-4'
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'AutoWriteOff',
        cssClass: 'p-col-12 p-md-4'
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'DateAcct',
        cssClass: 'p-col-12 p-md-4'
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Processing',
        cssClass: 'p-col-12 p-md-3 p-lg-2'
      }
    );
  }

  ngOnInit() {
    super.ngOnInit();
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    // * initialisation gridViews
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.gridOptions.singleClickEdit = true;
    this.gridViews[1].GridTabInfinityScrollUiComponent.agGrid.gridOptions.singleClickEdit = true;

    // * Récupération des ID des formDetail
    this.paymentGridID = this.fields.find(
      (el) => el.component === 'GridViewUiComponent' && el.name === 'Affectation paiements Table_Paiement'
    ).formDetailId;
    this.invoiceGridID = this.fields.find(
      (el) => el.component === 'GridViewUiComponent' && el.name === 'Affectation paiements Table_Facture'
    ).formDetailId;
  }

  initialize() {
    this.sumOfInvoices = 0;
    this.sumOfPayments = 0;
    this.dataStore.data.DifferenceAmt = 0;
  }

  roundAmount(value: any): number {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value.toFixed(2));
  }
  onCellEdited(event: CellEditingStoppedEvent) {
    switch (event.colDef.cellEditorParams.templates.formDetailId) {
      case this.paymentGridID:
        if (event.colDef.cellEditorParams.templates.columnName === 'AppliedAmt') {
          if(event.node.data.AppliedAmt != 0)
              event.node.setSelected(true); 
          else
            event.node.setSelected(false);    

          event.node.setDataValue('AppliedAmt', this.roundAmount(event.node.data.AppliedAmt));
          this.calcDiff();
        }
        break;
      case this.invoiceGridID:

        if(event.node.data.AppliedAmt != 0 || event.node.data.DiscountAmt != 0 || event.node.data.WriteOffAmt != 0)
            event.node.setSelected(true); 
        else
            event.node.setSelected(false); 

        if (
          event.colDef.cellEditorParams.templates.columnName === 'DiscountAmt' ||
          event.colDef.cellEditorParams.templates.columnName === 'WriteOffAmt'
        ) {
          event.colDef.cellEditorParams.templates.columnName === 'DiscountAmt'
            ? event.node.setDataValue('DiscountAmt', this.roundAmount(event.node.data.DiscountAmt))
            : event.node.setDataValue('WriteOffAmt', this.roundAmount(event.node.data.WriteOffAmt));
          this.resetAppliedAmtOfRow(event);
          this.calcDiff();
        } else if (event.colDef.cellEditorParams.templates.columnName === 'AppliedAmt') {
          event.node.setDataValue('AppliedAmt', this.roundAmount(event.node.data.AppliedAmt));
          this.calcDiff();
        }
        break;
      default:
        break;
    }
  }

  notifyFromDataChange(item: any) {
    if (item.data.columnName === 'MultiCurrency') {
      const datacontainer = this.dataContainers.find((elem) => elem.data.columnName === 'Currency_To_ID');
      if (datacontainer !== undefined) {
        if (datacontainer.fieldValue.id !== parseInt(this.connectorService.getIupicsUserContext()['$C_Currency_ID'], 10)) {
          datacontainer.updateStore(parseInt(this.connectorService.getIupicsUserContext()['$C_Currency_ID'], 10));
        }
      }
    }

    if (item.data['isLaunchSearchGrid']) {
      this.initialize();
      if (item.data.isLaunchSearchGrid) {
        this.refreshGrids(this.dataStore, false, item.data);
      }
      this.refreshDateAcct();
    }
  }

  notifySelect(gridView: GridViewUiComponent, rowSelected: any) {
    if (gridView.data['AD_FormDetail_ID'] === this.invoiceGridID && !gridView.GridTabInfinityScrollUiComponent.agGrid.pivotMode) {
      gridView.GridTabInfinityScrollUiComponent.agGrid.api.forEachNode((row: RowNode) => {
        if (!row.isSelected()) {
          const updated = row.data;
          if (updated) {
            updated.DiscountAmt = updated.WriteOffAmt = updated.AppliedAmt = 0;
            row.setData(updated);
          }        
        }
      });
      gridView.GridTabInfinityScrollUiComponent.agGrid.api.getSelectedNodes().forEach((row: RowNode) => {
        const updated = row.data;
        if (updated) {

          // #134144
          if(updated.AppliedAmt === 0 ){
             updated.AppliedAmt = updated.ConvertedOpenAmt - updated.DiscountAmt - updated.WriteOffAmt;             
          }

          updated.AppliedAmt = this.roundAmount(updated.AppliedAmt);
          row.setData(updated);
        }
      });
    } else if (
      gridView.data['AD_FormDetail_ID'] === this.paymentGridID &&
      !gridView.GridTabInfinityScrollUiComponent.agGrid.pivotMode
    ) {
      gridView.GridTabInfinityScrollUiComponent.agGrid.api.forEachNode((row: RowNode) => {
        if (!row.isSelected()) {
         
          const updated = row.data;
          if (updated) {              
            updated.AppliedAmt = 0;
            row.setData(updated);
          }
        }
      });
      gridView.GridTabInfinityScrollUiComponent.agGrid.api.getSelectedNodes().forEach((row: RowNode) => {
        const updated = row.data;
        if (updated) {
          if(updated.AppliedAmt === 0)
            updated.AppliedAmt = updated.ConvertedOpenAmt;
          row.setData(updated);
        }
      });
    }
    this.refreshDateAcct();
    this.calcDiff();
  }

  checkBeforeProcessing() {
    const SelectedNodesPay = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedNodes();
    const SelectedNodesInv = this.gridViews[1].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedNodes();
    if (
      this.dataStore.data.DifferenceAmt !== null &&
      this.dataStore.data.DifferenceAmt !== 0 &&
      (SelectedNodesPay.length > 0 || SelectedNodesInv.length > 0)
    ) {
      const field = this.fields.find((f) => f.data.columnName === 'DifferenceAmt');
      if (!field) {
        return false;
      }
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translator.instant('generic.error'),
          this.translator.instant('specificWindow.paymentAllocation.difference', {
            label: field.data.label,
            colName: field.data.columnName
          }),
          'error'
        )
      );
    } else {
      return true;
    }
  }

  onEndProcess(ping: ProcessPingInfo) {
    super.onEndProcess(ping);
    this.initialize();
  }

  private resetAppliedAmtOfRow(row: CellEditingStoppedEvent) {
    const appliedAmt = row.data.ConvertedOpenAmt - row.data.DiscountAmt - row.data.WriteOffAmt;
    row.node.setDataValue('AppliedAmt', this.roundAmount(appliedAmt));
  }

  private calcDiff() {
    const reducer = (accumulator: number, current: any) => accumulator + current.AppliedAmt;
    const selectedRowPayment = this.dataStore.data.selections.find((gridSel) => gridSel.AD_FormDetail_ID === this.paymentGridID)
      .selection as any[];
    const selectedRowInvoice = this.dataStore.data.selections.find((gridSel) => gridSel.AD_FormDetail_ID === this.invoiceGridID)
      .selection as any[];

    this.sumOfPayments = selectedRowPayment.length > 0 ? selectedRowPayment.reduce(reducer, 0) : 0;
    this.sumOfInvoices = selectedRowInvoice.length > 0 ? selectedRowInvoice.reduce(reducer, 0) : 0;

    this.refreshDifference();
  }

  private refreshDateAcct() {
    const datacontainer = this.dataContainers.find((elem) => elem.data.columnName === 'DateAcct');
    const selections = [].concat.apply(
      [],
      this.dataStore.data.selections.map((item: any) => item.selection)
    ) as any[];
    if (selections.length > 0) {
      const t = selections.map((s) => moment(s.DateAcct).valueOf()) as number[];
      const maxDate = Math.max(...t);
      datacontainer.updateStore(maxDate);
    } else {
      datacontainer.updateStore(this.connectorService.getIupicsUserContext()['#Date']);
    }
  }

  private refreshDifference() {
    this.dataStore.data.DifferenceAmt = 0;
    this.dataStore.data.DifferenceAmt = this.sumOfPayments - this.sumOfInvoices;
    const datacontainer = this.dataContainers.find((elem) => elem.data.columnName === 'DifferenceAmt');
    if (datacontainer !== undefined && datacontainer.fieldValue !== this.dataStore.data.DifferenceAmt) {
      datacontainer.updateStore(this.roundAmount(this.dataStore.data.DifferenceAmt));
    }
  }
}
