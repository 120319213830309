import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { PoService } from '@compiere-ws/services/po/po.service';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { isNil } from 'lodash';
import Quill from 'quill';

@Component({
  selector: 'iu-input-text-html-ui',
  templateUrl: './input-text-html-ui.component.html',
  styleUrls: ['./input-text-html-ui.component.scss']
})
export class InputTextHtmlUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('quillHeader', { read: ElementRef, static: true })
  header: ElementRef<HTMLDivElement>;
  @ViewChild('quillEditor', { read: ElementRef, static: true })
  editor: ElementRef<HTMLDivElement>;

  @Input()
  columnName: string;

  quill: Quill;

  displayMobileMenu = false;
  isMobile = false;

  dataContainers: AbstractDataContainerCallout;

  private __fieldValue: any;
  set fieldValue(value: any) {
    this.__fieldValue = value;
    this.updateQuillContent();
  }

  get fieldValue(): any {
    return this.__fieldValue;
  }

  private blurListener: () => void = () => {};

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public uiCreatorService: UICreatorService,
    public cmService: ContextMenuService,
    public renderer: Renderer2,
    protected po: PoService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.cssClass !== undefined) {
      this.cssGrid = this.cssClass;
    }
    this.cssClass = ' ' + this.cssGrid;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }

    this.quill = new Quill(this.editor.nativeElement, {
      modules: {
        toolbar: this.header.nativeElement
      },
      theme: 'snow'
    });
    this.updateQuillContent();

    this.blurListener = this.renderer.listen(this.quill.root, 'blur', (e: FocusEvent) => {
      if (
        isNil(e.relatedTarget) ||
        (!(e.relatedTarget as HTMLElement)?.hasAttribute('data-quill-action') &&
          !(e.relatedTarget as HTMLElement)?.classList.contains('ql-picker-label'))
      ) {
        const newContent = this.quill.root.innerHTML;
        if (newContent !== this.fieldValue) {
          this.dataChange(newContent);
        }
      }
    });

    this.quill.update();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.blurListener();
  }

  updateQuillContent() {
    if (this.quill?.root) {
      this.quill.root.innerHTML = this.fieldValue || '';
    }
  }
}
