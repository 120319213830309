import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { UploadedFile } from '@iupics-components/models/uploaded-file';
import { FilterListOperator, FilterListOptions } from '@iupics-components/pipes/filter-list/filter-list.pipe';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { PreviewDocComponent } from '@iupics-components/standard/preview-doc/preview-doc.component';
import { OverridedCSS } from '@iupics-manager/models/overrided-css';
import { TranslateService } from '@ngx-translate/core';
import * as mime from 'mime';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
@Component({
  selector: 'iu-prime-fileupload',
  templateUrl: './prime-fileupload.component.html',
  styleUrls: ['./prime-fileupload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrimeFileuploadComponent extends FileUpload implements OnInit {
  @ViewChild(PreviewDocComponent)
  private previewDocComponent: PreviewDocComponent;
  @ViewChild('advancedfileinput') advancedfileinput: ElementRef;
  @Output()
  uploadFiles: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output()
  downloadEmitter = new EventEmitter<any>();
  @Output()
  deleteEmitter = new EventEmitter<any>();
  @Output()
  fileSelectEmitter = new EventEmitter<any>();
  @Output()
  previewFileEmitter = new EventEmitter<any>();

  @Input()
  uploadedFiles: UploadedFile[] = [];
  @Input()
  linkedFiles: UploadedFile[] = [];
  @Input()
  uploadingFiles = <any>[];
  @Input()
  displayButtons = true;
  @Input()
  displayFileUploaded = true;
  @Input()
  isLoaderActive = false;
  @Input()
  isReadOnly = false;
  @Input()
  canUpload = true;
  @Input()
  canDelete = true;
  @Input()
  canDownload = true;
  @Input()
  canPreview = false;
  @Input()
  linkedFileList_title = 'fileupload.linked_files_list';
  @Input()
  uploadedFileList_noLinkedFiles = 'fileupload.no_linked_files';
  @Input()
  uploadedFileList_title = 'fileupload.files_list';
  @Input()
  uploadedFileList_noFiles = 'fileupload.no_files';
  @Input()
  uploadingFileList_title = 'fileupload.add_files';
  @Input()
  uploadingFileList_drag = 'fileupload.label';
  @Input()
  uploadBtn_confirm = 'fileupload.upload_files';
  @Input()
  uploadedFileList_search = 'fileupload.inputSearch';
  @Input()
  fromDocServer = true;
  @Input()
  uploadBtn_cancel = 'fileupload.clear';
  @Input()
  overridedCSS: OverridedCSS;
  filterListOptions: FilterListOptions;
  filterLinkedListOptions: FilterListOptions;
  constructor(
    el: ElementRef,
    sanitizer: DomSanitizer,
    zone: NgZone,
    httpClient: HttpClient,
    cd: ChangeDetectorRef,
    private docServerService: DocServerService,
    private messageService: MessageService,
    private translator: TranslateService
  ) {
    super(el, sanitizer, zone, httpClient, cd);
  }

  ngOnInit() {}

  getLogoFileSrc(file: any) {
    if (this.isImage(file)) {
      return file.objectURL;
    } else {
      const fileName = file.name ? file.name.toLowerCase() : '';
      if (fileName.endsWith('.csv')) {
        return 'assets/csv.png';
      } else if (fileName.endsWith('.txt')) {
        return 'assets/txt.png';
      } else if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
        return 'assets/word.png';
      } else if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
        return 'assets/excel.png';
      } else if (fileName.endsWith('.pdf')) {
        return 'assets/pdf.png';
      } else if (fileName.endsWith('.html')) {
        return 'assets/html.png';
      } else if (
        fileName &&
        (fileName.endsWith('.png') ||
          fileName.endsWith('.jpg') ||
          fileName.endsWith('.jpeg') ||
          fileName.endsWith('.gif') ||
          fileName.endsWith('.bmp') ||
          fileName.endsWith('.ico') ||
          fileName.endsWith('.webp') ||
          fileName.endsWith('.svg'))
      ) {
        const urlPreview = this.fromDocServer ? this.docServerService.getUrlPreview(file.src) : file.src;
        return urlPreview;
      } else {
        return 'assets/file.png';
      }
    }
  }

  addFile(event: Event) {
    event.stopPropagation();
    // simule un click sur le input file.
    this.advancedfileinput.nativeElement.click();
  }

  remove(event: Event, i: number) {
    // retirer un fichier de la liste des fichiers à uploadé.
    event.stopPropagation();
    super.remove(event, i);
  }

  /**
   * Demande au parent l'upload des fichiers de la liste
   */
  onClickUploadFiles() {
    this.uploadFiles.emit(this.files);
  }

  /**
   * Appellé par le parent (input-file-ui) pour retirer un fichier de la liste
   * @param {File} file
   */
  removeFile(file: File) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  /**
   * lance l'emitter pour le téléchargement du fichier
   * @param {Event} event
   * @param {string} docId
   * @param {index} index
   */
  downloadFile(event: Event, file: UploadedFile, index: number) {
    event.stopPropagation();
    this.downloadEmitter.emit({ file: file });
  }
  /**
   * lance la copie du lien du fichier
   * @param {Event} event
   * @param {string} docId
   * @param {index} index
   */
  copyLinkFile(event: Event, file: UploadedFile, index: number) {
    event.stopPropagation();
    const urlPreview = this.fromDocServer ? this.docServerService.getUrlPreview(file.src as string) : file.src;
    EditViewUtils.copyToClipboard(urlPreview as string);
    this.messageService.add({
      key: 'quick-toast',
      severity: 'success',
      summary: this.translator.instant('generic.success'),
      detail: this.translator.instant('joinFiles.tooltip_copied'),
      life: 1500,
      closable: false
    });
  }

  /**
   * Lance l'emitter pour la suppression du fichier
   * @param {Event} event
   * @param {string} docId
   * @param {index} index
   */
  deleteFile(event: Event, file: UploadedFile, index: number) {
    event.stopPropagation();
    this.deleteEmitter.emit({ file, index });
  }

  onFileSelect(event) {
    super.onFileSelect(event);
    this.fileSelectEmitter.emit(event);
  }

  hasPreview(file: UploadedFile) {
    let hasPreview = false;
    const extFromFileName = file.name ? (file.name as string).toLocaleLowerCase().split('.').pop() : null;
    const ext = file.extension ? (file.extension as string).toLocaleLowerCase().split('.').pop() : null;
    if (extFromFileName === 'pdf' || ext === 'pdf') {
      hasPreview = true;
    }
    if (!hasPreview) {
      const mimeTypeFromFileName = mime.getType(extFromFileName);
      const mimeType = mime.getType(ext);
      if ((mimeTypeFromFileName && mimeTypeFromFileName.includes('image')) || (mimeType && mimeType.includes('image'))) {
        hasPreview = true;
      }
    }
    return hasPreview;
  }
  onPreview(event, file: UploadedFile, index: number) {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    if (this.hasPreview(file)) {
      const fileName = file.extension
        ? (file.name as string).endsWith(`.${file.extension.replace(/^\./, '')}`)
          ? file.name
          : `${file.name}.${file.extension}`
        : file.name;
      this.previewDocComponent.onPreview(fileName, file.src);
    }
  }
  updateFilterOptions(event: any, inputValue: string, src: string) {
    if (src === 'files') {
      this.filterListOptions = { objProperties: ['name'], operator: FilterListOperator.CONTAINS, value: inputValue };
    }
    if (src == 'linkedFiles') {
      this.filterLinkedListOptions = { objProperties: ['name'], operator: FilterListOperator.CONTAINS, value: inputValue };
    }
  }
}
