import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Calendar } from 'primeng/calendar';
import { DomHandler } from 'primeng/dom';

export const CALENDAR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PrimeCalendarComponent),
  multi: true
};
@Component({
  selector: 'iu-prime-calendar',
  templateUrl: './prime-calendar.component.html',
  styleUrls: ['./prime-calendar.component.scss'],
  animations: Global.overlayAnimationCalendar,
  providers: [CALENDAR_VALUE_ACCESSOR]
})
export class PrimeCalendarComponent extends Calendar implements OnInit {
  @Input() columnName: string;
  @Input() label: string;
  @Input() isLabelDisplay: boolean;
  @Input() isStandalone = false;
  @Input() isGridRenderer: boolean;
  @Input() iupicsLocale: string;

  @Output() fieldChange = new EventEmitter<any>();
  @Output() checkGridRendererEmitter = new EventEmitter<any>();
  @Output() selectItemEmitter: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputfield', { static: true }) inputRef: ElementRef;

  mandatoryCss: string;
  showTransitionOptions = '225ms ease-out';
  hideTransitionOptions = '195ms ease-in';

  private timeOnlyInfos: {
    date: any;
    month: any;
    fullYear: any;
    seconds: any;
    milliseconds: any;
  };

  @Input() get showTime(): boolean {
    return this._showTime;
  }

  set showTime(showTime: boolean) {
    this._showTime = showTime;
    if (this._showTime && this.isStandalone && this.currentHour === undefined && this.value) {
      this.value = new Date(this.value);
      this.initTime(this.value || new Date());
    }
  }

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public cd: ChangeDetectorRef,
    private translateService: TranslateService,
    zone: NgZone
  ) {
    super(el, renderer, cd, zone);
  }

  ngOnInit() {
    super.ngOnInit();
    this.appendTo = 'body';
    // @custo_code
    this.locale = this.translateService.translations[this.iupicsLocale.replace(/-/g, '_')].calendar; //Redmine 127611 
    if(this.locale.today==="Today"){
      moment.locale("fr");  
    }
    else{
      moment.locale(this.iupicsLocale);
    }  //End Redmine 127611
    this.dateFormat = this.locale.dateFormat;
    this.initTime(new Date());
    const date: Date = new Date();
    this.createMonth(date.getMonth(), date.getFullYear());
    this.changeFloatLabelCss();
    this.selectOtherMonths = true;
    if (this.timeOnly) {
      this.saveInfoForTimeOnly();
    }
  }

  private saveInfoForTimeOnly() {
    const _date = this.value || new Date();
    this.timeOnlyInfos = {
      date: (_date as Date).getDate(),
      month: (_date as Date).getMonth(),
      fullYear: (_date as Date).getFullYear(),
      seconds: (_date as Date).getSeconds(),
      milliseconds: (_date as Date).getMilliseconds()
    };
  }

  setMandatoryCss(css: string) {
    this.mandatoryCss = css;
  }

  changeFloatLabelCss() {
    // if (this.value) {
    //   this.styleClass = ' iu-label-autoc-filled  ';
    // } else {
    //   this.styleClass = ' iu-label-autoc  ';
    // }
  }
  cancelFocus(event: MouseEvent) {
    if (event && event.button === 2) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
  onInputFocus(event: Event) {
    this.focus = true;
    if (this.showOnFocus) {
      if (this.isGridRenderer) {
        this.checkGridRendererEmitter.emit();
      } else {
        this.showOverlay();
      }
    }
    this.onFocus.emit(event);
  }

  onInputClick() {
    if (this.overlay && this.autoZIndex) {
      this.overlay.style.zIndex = String(this.baseZIndex + ++DomHandler.zindex);
    }
  }

  onInputBlur(event: Event) {
    this.focus = false;
    this.keepInvalid = false;
    if (!this.keepInvalid) {
      this.updateInputfield();
    }
    this.onModelTouched();
  }

  updateInputfield() {
    this.setFieldValue();
    this.fieldChange.emit(this.value);
    this.selectItemEmitter.emit();
  }

  setFieldValue() {
    let formattedValue = '';
    if (this.value && !this.timeOnly) {
      if (this.isSingleSelection()) {
        formattedValue = moment(this.value).format('L').slice(0, 10);
        if (this.showTime) {
          formattedValue += ' ' + moment(this.value).format('LT');
        }
      } else if (this.isMultipleSelection()) {
        for (let i = 0; i < this.value.length; i++) {
          let dateAsString = moment(this.value[i]).format('L').slice(0, 10);
          if (this.showTime) {
            dateAsString += ' ' + moment(this.value).format('LT');
          }
          formattedValue += dateAsString;
          if (i !== this.value.length - 1) {
            formattedValue += ', ';
          }
        }
      } else if (this.isRangeSelection()) {
        if (this.value && this.value.length) {
          const startDate = this.value[0];
          const endDate = this.value[1];

          formattedValue = moment(startDate).format('L').slice(0, 10);
          if (this.showTime) {
            formattedValue += ' ' + moment(this.value).format('LT');
          }
          if (endDate) {
            formattedValue += ' - ' + moment(endDate).format('L').slice(0, 10);
            if (this.showTime) {
              formattedValue += ' ' + moment(this.value).format('LT');
            }
          }
        }
      }
    }
    if (this.timeOnly) {
      this.inputFieldValue = this.formatTime(this.value);
    } else {
      this.inputFieldValue = formattedValue;
    }
    this.updateFilledState();
    if (this.inputfieldViewChild && this.inputfieldViewChild.nativeElement) {
      this.inputfieldViewChild.nativeElement.value = this.inputFieldValue;
    }
    this.changeFloatLabelCss();
    if (!this.value) {
      this.inputFieldValue = null;
    }
  }

  onChange(event) {
    this.updateInputfield();
  }

  onUserInput(event) {
    // IE 11 Workaround for input placeholder : https://github.com/primefaces/primeng/issues/2026
    if (!this.isKeydown) {
      return;
    }
    if (this.timeOnly) {
      if (event.inputType === 'deleteContentBackward') {
        if (event.target.value.length === 2 || event.target.value.length === 5) {
          event.target.value = event.target.value.slice(0, -1);
        }
      } else if (!/[0-9]|:/.test(event.data)) {
        event.target.value = event.target.value.slice(0, -1);
      } else if (event.target.value.length === 2) {
        event.target.value += ':';
      }
    } else {
      if (event.inputType === 'deleteContentBackward') {
        if (event.target.value.length === 2 || event.target.value.length === 5) {
          event.target.value = event.target.value.slice(0, -1);
        }
      } else if (event.data === '/') {
        event.target.value = event.target.value.slice(0, -1);
      } else if (isNaN(Number(event.data)) || event.data === ' ') {
        event.target.value = event.target.value.slice(0, -1);
      } else if (event.target.value.length === 2 || event.target.value.length === 5) {
        event.target.value += '/';
      } else if (!this.showTime && event.target.value.length > 10) {
        event.target.value = event.target.value.slice(0, 10);
      }

      if (this.showTime) {
        if (event.target.value.length === 10) {
          event.target.value += ' ';
        } else if (event.target.value.length === 13) {
          event.target.value += ':';
        } else if (event.target.value.length === 16) {
          if (event.data === 'a' || event.data === 'A') {
            event.target.value += 'AM';
          } else if (event.data === 'p' || event.data === 'P') {
            event.target.value += 'PM';
          }
        } else if (event.target.value.length > 18) {
          event.target.value = event.target.value.slice(0, 18);
        }
      }
    }

    this.isKeydown = false;

    let val: string = event.target.value;
    if (this.timeOnly) {
      const s = val.split(':');
      if (Number.parseInt(s[0], 10) > 23) {
        s[0] = '23';
      }
      if (Number.parseInt(s[1], 10) > 59) {
        s[1] = '59';
      }
      val = s.join(':');
      event.target.value = val;
    }

    val = val.endsWith(':') ? val + '00' : val;

    try {
      const value: Date = this.timeOnly ? this.parseDateTime(val) : this.parseValueFromString(val);
      if (this.timeOnly) {
        value.setDate(this.timeOnlyInfos.date);
        value.setMonth(this.timeOnlyInfos.month);
        value.setFullYear(this.timeOnlyInfos.fullYear);
        value.setSeconds(this.timeOnlyInfos.seconds);
        value.setMilliseconds(this.timeOnlyInfos.milliseconds);
      }
      if (this.isSelectable(value.getDate(), value.getMonth(), value.getFullYear(), false) || this.timeOnly) {
        this.updateModel(value);
        this.updateUI();
        if (this.isGridRenderer) {
          this.checkGridRendererEmitter.emit();
        }
      }
    } catch (err) {
      // invalid date
      this.updateModel(null);
    }

    this.filled = val != null && val.length > 0;
    this.onInput.emit(event);
  }

  parseValueFromString(text: string): Date {
    if (!text || text.trim().length === 0) {
      return null;
    } else if (text.trim().length === 2 || text.trim().length === 5) {
      this.inputRef.nativeElement.value += '/';
    }

    let value: any;

    if (this.isSingleSelection()) {
      value = this.parseDateTime(text);
    } else if (this.isMultipleSelection()) {
      const tokens = text.split(',');
      value = [];
      for (const token of tokens) {
        value.push(this.parseDateTime(token.trim()));
      }
    } else if (this.isRangeSelection()) {
      const tokens = text.split(' - ');
      value = [];
      for (let i = 0; i < tokens.length; i++) {
        value[i] = this.parseDateTime(tokens[i].trim());
      }
    }

    return value;
  }

  onTimePickerElementMouseDown(event: Event, type: number, direction: number) {
    if (!this.disabled) {
      this.repeat(event, null, type, direction);
      event.preventDefault();
    }
  }

  onTimePickerElementMouseUp(event: Event) {
    if (!this.disabled) {
      this.clearTimePickerTimer();
      this.updateTime();
    }
  }

  repeat(event: Event, interval: number, type: number, direction: number) {
    const i = interval || 500;

    this.clearTimePickerTimer();
    this.timePickerTimer = setTimeout(() => {
      this.repeat(event, 100, type, direction);
    }, i);

    switch (type) {
      case 0:
        if (direction === 1) {
          this.incrementHour(event);
        } else {
          this.decrementHour(event);
        }
        break;

      case 1:
        if (direction === 1) {
          this.incrementMinute(event);
        } else {
          this.decrementMinute(event);
        }
        break;

      case 2:
        if (direction === 1) {
          this.incrementSecond(event);
        } else {
          this.decrementSecond(event);
        }
        break;
    }
  }

  updateTime() {
    let value = this.value;
    if (this.isRangeSelection()) {
      value = this.value[1] || this.value[0];
    }
    if (this.isMultipleSelection()) {
      value = this.value[this.value.length - 1];
    }
    value = value ? new Date(value.getTime()) : new Date();

    if (this.value) {
      if (this.hourFormat == '12') {
        if (this.currentHour === 12) value.setHours(this.pm ? 12 : 0);
        else value.setHours(this.pm ? this.currentHour + 12 : this.currentHour);
      } else {
        value.setHours(this.currentHour);
      }

      value.setMinutes(this.currentMinute);
      value.setSeconds(this.currentSecond);
    } else {
      this.currentHour = value.getHours();
    }
    if (this.isRangeSelection()) {
      if (this.value[1]) value = [this.value[0], value];
      else value = [value, null];
    }

    if (this.isMultipleSelection()) {
      value = [...this.value.slice(0, -1), value];
    }

    this.updateModel(value);
    this.onSelect.emit(value);
    this.setFieldValue();
    this.updateInputfield();
  }

  showOverlay() {
    if (!this.overlayVisible) {
      this.updateUI();
      this.overlayVisible = true;
      if (this.timeOnly && this.inputFieldValue === null) {
        this.onTimePickerElementMouseDown(new MouseEvent('click'), 0, 0);
        this.onTimePickerElementMouseUp(new MouseEvent('click'));
      }
    }
  }

  onButtonClick(event, inputfield) {
    if (this.isGridRenderer) {
      this.checkGridRendererEmitter.emit();
    } else {
      if (!this.overlayVisible) {
        inputfield.focus();
        this.showOverlay();
      } else {
        this.hideOverlay();
      }
    }
  }

  navForward(event) {
    event.stopPropagation();

    if (this.disabled) {
      event.preventDefault();
      return;
    }

    if (this.view === 'month') {
      this.incrementYear();
    } else {
      if (this.currentMonth === 11) {
        this.currentMonth = 0;
        this.incrementYear();
      } else {
        this.currentMonth++;
      }

      this.onMonthChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
      this.createMonths(this.currentMonth, this.currentYear);
    }
  }
  navBackward(event) {
    event.stopPropagation();

    if (this.disabled) {
      event.preventDefault();
      return;
    }

    if (this.view === 'month') {
      this.decrementYear();
    } else {
      if (this.currentMonth === 0) {
        this.currentMonth = 11;
        this.decrementYear();
      } else {
        this.currentMonth--;
      }

      this.onMonthChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
      this.createMonths(this.currentMonth, this.currentYear);
    }
  }
  @HostListener('document:keydown.tab', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
    if (this.overlayVisible) {
      this.hideOverlay();
    }
  }
}
