<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window fast']"
  [attr.data-cy-specific-window-name]="name"
>
  <!-- @custo_code -->
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div id="page-wrap">
      <h1>{{ 'specificWindow.quickOrder.title' | translate }} ( {{ docStatus }} )</h1>
      <div id="customer">
        <div class="row">
          <table class="metaleft p-md-4 p-sm-12" id="meta">
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.customer' | translate }}</td>
              <td class="p-grid"><ng-template #vcrBPartner></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.customerLocation' | translate }}</td>
              <td class="p-grid"><ng-template #vcrBPartnerLocation></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.docTypeTarget' | translate }}</td>
              <!-- <td class="p-grid"><ng-template #vcrDeliveryViaRule></ng-template></td> -->
              <td class="p-grid"><ng-template #vcrDocType></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.paymentRule' | translate }}</td>
              <!-- <td class="p-grid"><ng-template #vcrDeliveryRule></ng-template></td> -->
              <td class="p-grid"><ng-template #vcrPaymentRule></ng-template></td>
            </tr>
          </table>

          <table class="metaright p-md-4 p-sm-12" id="meta">
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.order' | translate }}</td>
              <td class="p-grid"><ng-template #vcrOrder></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.poReference' | translate }}</td>
              <td class="p-grid"><ng-template #vcrPOReference></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.dateOrdered' | translate }}</td>
              <td class="p-grid"><ng-template #vcrDateOrdered></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.priceList' | translate }}</td>
              <!-- <td class="p-grid"><ng-template #vcrMShipper></ng-template></td> -->
              <td class="p-grid"><ng-template #vcrMPriceList></ng-template></td>
            </tr>
          </table>
        </div>
        <a class="settings" (click)="isSidebarOpen = !isSidebarOpen"><i class="fas fa-cog"></i></a>
      </div>
      <table id="items">
        <colgroup>
          <col class="product-column" />
          <col class="description-column" />
          <col class="small-column" />
          <col class="small-column" />
          <col class="small-column" />
          <col class="small-column" />
          <col class="small-column" />
          <col class="delete-column" />
        </colgroup>
        <thead>
          <tr>
            <th>{{ 'specificWindow.quickOrder.product' | translate }}</th>
            <th>{{ 'specificWindow.quickOrder.description' | translate }}</th>
            <th class="alignRight">{{ 'specificWindow.quickOrder.qtyOnHand' | translate }}</th>
            <th class="alignRight">{{ 'specificWindow.quickOrder.unitPrice' | translate }}</th>
            <th>{{ 'specificWindow.quickOrder.quantity' | translate }}</th>
            <th>{{ 'specificWindow.quickOrder.discount' | translate }}</th>
            <th class="alignRight">{{ 'specificWindow.quickOrder.totalLine' | translate }}</th>
            <th class="alignRight"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of dataLines.length + 1 | fakeArray; let i = index">
            <tr class="item-row">
              <td>
                <ng-template #vcrProduct></ng-template>
              </td>
              <td><ng-template #vcrDescription></ng-template></td>
              <td>
                <ng-template #vcrQtyOnHand></ng-template>
              </td>
              <td class="fastorder-price">
                <ng-template #vcrPriceList></ng-template>
                <div [hidden]="true">
                  <ng-template #vcrPriceActual></ng-template>
                  <ng-template #vcrPriceEntered></ng-template>
                </div>
              </td>
              <td class="fastorder-qty"><ng-template #vcrQty></ng-template></td>
              <td class="fastorder-discount"><ng-template #vcrDiscount></ng-template></td>
              <td class="fastorder-linetotal"><ng-template #vcrLineTotalAmt></ng-template></td>
              <td class="deleteButton">
                {{ item }}
                <button
                  *ngIf="i < $any(dataLines).length"
                  [ngClass]="[
                    dataStore &&
                    dataStore.data['DocStatus'] &&
                    (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                      ? 'disabledButton'
                      : ''
                  ]"
                  tooltipPosition="top"
                  showDelay="250"
                  life="3"
                  pTooltip="{{ 'generic.delete' | translate }}"
                  pButton
                  icon="icon-ico-delete"
                  (click)="removeLine(dataLines[i], i)"
                ></button>
              </td>
            </tr>
          </ng-container>
          <tr id="hiderow">
            <td colspan="8">
              <button
                id="addrow"
                [ngClass]="[
                  dataStore &&
                  (!dataStore.data['C_Order_ID'] ||
                    (dataStore.data['DocStatus'] &&
                      (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')))
                    ? 'disabledButton'
                    : '',
                  'p-button p-button-secondary'
                ]"
                title="Add a row"
                (click)="newLine()"
              >
                <span class="icon-ico-add"></span>{{ 'specificWindow.quickOrder.addrow' | translate }}
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="2" class="nb-lines">
              {{ 'specificWindow.quickOrder.nbLines' | translate }}: {{ dataLines?.length || 0 }}
            </td>
            <td colspan="3"></td>
            <!-- <td colspan="4" class="blank"></td> -->
            <td class="total-line balance">{{ 'specificWindow.quickOrder.orderTotal' | translate }}</td>
            <td colspan="2" class="total-value balance fastorder-grandtotal"><ng-template #vcrGrandTotal></ng-template></td>
          </tr>
          <tr>
            <td class="buttonsExecute" colspan="8">
              <button
                pButton
                class="p-button-info"
                icon="fa fa-calculator"
                (click)="$event.preventDefault(); $event.stopPropagation(); openCalculateChangePopUp()"
              ></button>

              <button
                *ngIf="
                  dataStore &&
                  dataStore.data['C_Order_ID'] &&
                  dataStore.data['C_Order_ID']?.id > 0 &&
                  dataStore.data['DocStatus'] &&
                  (dataStore.data['DocStatus'] === 'DR' ||
                    (dataStore.data['DocStatus'].id && dataStore.data['DocStatus'].id === 'DR'))
                "
                pButton
                class="p-button-error"
                icon="icon-ico-delete"
                (click)="$event.preventDefault(); $event.stopPropagation(); cancelOrder()"
              ></button>
              <button
                [ngClass]="['p-button primary']"
                [disabled]="
                  dataStore &&
                  dataStore.data['DocStatus'] &&
                  (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                "
                (click)="$event.preventDefault(); $event.stopPropagation(); processOrder()"
              >
                <i style="margin-right: 3px" [ngClass]="['fa fa-check']"></i>
                {{ 'specificWindow.processing' | translate }}
              </button>
              <button
                [ngClass]="['p-button', 'p-button-secondary']"
                *ngIf="
                  dataStore &&
                  dataStore.data['DocStatus'] &&
                  (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                "
                (click)="$event.preventDefault(); $event.stopPropagation(); resetOrder()"
              >
                <i style="margin-right: 3px" [ngClass]="['fas fa-plus']"></i>
                {{ 'specificWindow.quickOrder.newOrder' | translate }}
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div style="height: 70px"></div>
  </p-scrollPanel>
  <p-sidebar [(visible)]="isSidebarOpen" [baseZIndex]="6000" position="right" styleClass="p-sidebar-md fast-create-order">
    <h1 class="iu-notification-center-title">
      {{ 'specificWindow.quickOrder.settings' | translate }}
    </h1>
    <ng-template #vcrSettings></ng-template>
  </p-sidebar>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [container]="this"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>

<p-toast position="center" [key]="popUpKey">
  <ng-template let-message pTemplate="message">
    <table class="fast-create-order-popup-table">
      <thead>
        <tr>
          <th colspan="4" class="fast-create-order-popup-table-title">
            {{ 'specificWindow.quickOrder.popupHeader' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="2">{{ 'specificWindow.quickOrder.popupIn1' | translate }}</td>
          <td>
            <iu-input-number-ui
              isReadOnly="true"
              isStandalone="true"
              [fieldValue]="popUpData?.toBePaid || 0"
            ></iu-input-number-ui>
          </td>
        </tr>
        <tr>
          <td colspan="2">{{ 'specificWindow.quickOrder.popupIn2' | translate }}</td>
          <td>
            <iu-input-number-ui
              isStandalone="true"
              [fieldValue]="popUpData?.customerIn || 0"
              (fieldValueModified)="calculeChange($event, changeOutInput)"
            ></iu-input-number-ui>
          </td>
        </tr>
        <tr>
          <td colspan="2">{{ 'specificWindow.quickOrder.popupOut' | translate }}</td>
          <td>
            <iu-input-number-ui
              #changeOutInput
              isReadOnly="true"
              isStandalone="true"
              [fieldValue]="popUpData?.changeOut || 0"
            ></iu-input-number-ui>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2" class="fast-create-order-popup-btn-left">
            <button pButton class="p-button-error" icon="icon-ico-close" (click)="closeCalculateChangePopUp()"></button>
          </td>
          <td colspan="2" class="fast-create-order-popup-btn-right">
            <button
              pButton
              class="p-button-success"
              icon="icon-ico-save3x"
              (click)="closeCalculateChangePopUp(); processOrder()"
            ></button>
          </td>
        </tr>
      </tfoot>
    </table>
  </ng-template>
</p-toast>
