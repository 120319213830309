import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  CompiereDataGridType,
  DataStore,
  DataStoreKey,
  DataStoreRequest,
  DataStoreStatus
} from '@compiere-ws/models/compiere-data-json';
import { PoService } from '@compiere-ws/services/po/po.service';
import { PrimeFieldsetComponent } from '@iupics-components/overrided/prime-fieldset/prime-fieldset.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryUtils } from '@iupics-manager/managers/ui-creator/window-factory/window-factory-utils';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { AbstractDynamicView } from '@iupics-manager/models/abstract-dynamic-view';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { AccordionUiComponent } from '../accordion-ui/accordion-ui.component';
import { AdditionalInfoUiComponent } from '../additional-info-ui/additional-info-ui.component';
import { EditViewUiComponent } from '../edit-view-ui/edit-view-ui.component';
import { EditViewUtils } from '../edit-view-ui/utils/edit-view.utils';
@Component({
  selector: 'iu-edit-tab-ui',
  templateUrl: './edit-tab-ui.component.html',
  styleUrls: ['./edit-tab-ui.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EditTabUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  private lastCssClass = '';
  isDisplay: 'inline' | 'none' = 'inline';
  isExpand = true;
  isTopEditTab = false;
  isCollapsed = false;
  childrenCreated = false;
  dataContainers: AbstractDataContainer[] = [];
  el: ElementRef;
  private _dataStoreKey: DataStoreKey;
  get dataStoreKey(): DataStoreKey {
    return this._dataStoreKey;
  }
  set dataStoreKey(dataStoreKey: DataStoreKey) {
    if (this._dataStoreKey !== dataStoreKey && this.data.isSingleRow) {
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.selectDataChange,
        item: {
          container: null,
          dataStoreKey: dataStoreKey
        }
      });
    }
    this._dataStoreKey = dataStoreKey;
    /*mettre à jour le datastore */
  }
  @Input()
  toggleable = true;
  @Input()
  private _collapsed = false;
  get collapsed(): boolean {
    return this._collapsed;
  }
  set collapsed(collapsed: boolean) {
    if (this.data.isSingleRow && !this.isTopEditTab) {
      collapsed = true;
    }
    if (collapsed === false) {
      // check si les enfants ont déjà été créés
      if (this.children && this.children.length > 0) {
        if (!this.childrenCreated) {
          this.buildChildren();
          this.childrenCreated = true;
          this.scrollTo();
        }
      }
    }
    this._collapsed = collapsed;
  }

  @ViewChild(PrimeFieldsetComponent, { static: true })
  fieldset: PrimeFieldsetComponent;

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public cmService: ContextMenuService,
    public uiCreatorService: UICreatorService,
    private resolver: ComponentFactoryResolver,
    renderer: Renderer2,
    protected po: PoService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po);
  }

  ngOnInit() {
    super.ngOnInit();
    // ajout de cette edit tab aux enfants de l'editview parent
    let parentComp = this.DOMParentComponent;
    while (!(parentComp instanceof EditViewUiComponent) && !(parentComp instanceof AdditionalInfoUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    // Vérifie si c'est un addtionalinfo pour ajouter l'editTab à l'editview qui le contient
    if (parentComp instanceof AdditionalInfoUiComponent) {
      (<EditViewUiComponent>parentComp.DOMParentComponent).addTabToEditView(this);
      (<EditViewUiComponent>parentComp.DOMParentComponent).additionalInfoComponent.addTabToEditView(this);
    } else {
      // on set la datastorekey venant de l'editviewparent
      (<AbstractDynamicView>parentComp).addTabToEditView(this);
    }
    this.dataStoreKey = (<EditViewUiComponent>parentComp).currentDataStoreKey;

    // vérifie l'état de l'onglet
    if (!this.isTopEditTab && ((this.data && this.data.isCollapsedDefault) || this.data.isSingleRow)) {
      this.collapsed = true;
    }
  }

  onChildUpdate(event: IupicsEvent) {}

  collaspTab() {
    if (!this.data.isCollapsable) {
      this.updateDisplayLogic();
    } else {
      if (this.lastCssClass === '') {
        this.lastCssClass = this.DOMParentComponent.cssClass;
        this.DOMParentComponent.cssClass = 'p-col-12 p-col-nopad';
      }
      this.isExpand = false;
    }
  }

  expandTab() {
    if (!this.data.isCollapsable) {
      this.updateDisplayLogic();
    } else if (!this.isExpand) {
      this.DOMParentComponent.cssClass = this.lastCssClass;
      this.lastCssClass = '';
      this.isExpand = true;
    }
    this.isCollapsed = false;
  }

  addDataContainerToEditTab(dataContainer: AbstractDataContainer) {
    // cette condition permet de ne pas ajouter les abstractData qui ne doivent pas se retrouver dans le datastore => exemple Statut dans tiers
    if (dataContainer.data.columnName) {
      // if (
      //   dataContainer.data.isAlwaysUpdatable === false &&
      //   this.dataStored.data.DocStatus &&
      //   this.dataStored.data.DocStatus.id !== 'DR'
      // ) {
      //   dataContainer.isReadOnly = true;
      // }
      this.dataContainers.push(dataContainer);
      this.subscriptions.push(
        dataContainer.fieldValueModified.subscribe((dataStored) => {
          let i = 0;
          this.editViewParent.editTabs.forEach((tab) => {
            if (i > 0) {
              const prevCss = tab.isDisplay;
              const isDisplayed = tab.updateDisplayLogic(dataStored);
              if (tab.children[0]) {
                if (!tab.data.isSingleRow) {
                  if (isDisplayed) {
                    if (prevCss === 'none') {
                      if (tab.DOMChildrenComponent[0]) {
                        (<GridViewUiComponent>(
                          tab.DOMChildrenComponent[0].DOMComponent.instance
                        )).GridTabInfinityScrollUiComponent.createServerDataSource(dataStored.data);
                      }
                    }
                  }
                } else {
                  if (isDisplayed) {
                    if (dataStored.key.tabId !== tab.tabId) {
                      const mapfilter = LogicEvaluator.parseLogic(
                        dataStored.data,
                        tab.gridTabFilter[0],
                        this.connectorService.getIupicsUserContext()
                      );
                      const dataStoreRequest: DataStoreRequest = {
                        windowId: dataStored.key.windowId,
                        record_id: dataStored.key.recordId,
                        parent_constraint: mapfilter,
                        compiereRequest: {
                          windowType: CompiereDataGridType.WINDOW,
                          entityId: tab.tabId,
                          startRow: 0,
                          endRow: 1
                        }
                      };
                      this.subscriptions.push(
                        this.store.getWindowSingleData(dataStoreRequest).subscribe((data) => {
                          tab.updateData(data);
                        })
                      );
                    }
                  }
                }
              }
            } else {
              tab.updateAllLogic(dataStored);
            }
            i++;
          });
        })
      );
    }
  }

  checkReadAndDisplay(dataStored: DataStore) {
    super.checkReadAndDisplay(dataStored);
    this.updateReadOnlyLogic(dataStored);
  }

  updateData(dataStored: DataStore, columnName?: string) {
    this.updateAllLogic(dataStored);
    if (dataStored) {
      if (
        dataStored.status === DataStoreStatus.NEWRECORD &&
        this.data.ctxArea &&
        dataStored.data[Object.keys(this.data.ctxArea)[0]] === null
      ) {
        const request: DataStoreRequest = {
          windowId: dataStored.key.windowId,
          record_id: dataStored.key.recordId,
          parent_constraint: dataStored.key.parentId,
          compiereRequest: {
            windowType: CompiereDataGridType.WINDOW,
            entityId: dataStored.key.tabId,
            startRow: 0,
            endRow: 1
          }
        };
        if (this.editViewParent) {
          let validation;
          if (this.data.validationCode) {
            validation = this.data.validationCode;
          }
          if (this.gridTabValidator && this.gridTabValidator.length > 0) {
            validation = validation ? validation + ' and ' + this.gridTabValidator[0] : this.gridTabValidator[0];
          }
          request.compiereRequest.validation = LogicEvaluator.parseLogic(
            this.getCurrentContext(dataStored),
            validation,
            this.connectorService.getIupicsUserContext()
          );
        }
        //check si on pointe sur la meme table que l'onglet 0
        if (
          this.data.isSingleRow &&
          this.data.TableName &&
          this.editViewParent?.linkedComponents[0]?.editViewParent?.data?.TableName === this.data.TableName
        ) {
          request.parent_constraint = '';
          request.compiereRequest.entityId = this.editViewParent.linkedComponents[0].editViewParent.tabId;
        }
        this.store.syncDataChanges(dataStored, this.data.ctxArea, true);
        this.store.getWindowSingleData(request).subscribe((dataWithCtx) => {
          this.dataStored = dataWithCtx;
          if (dataWithCtx && dataWithCtx.key && dataWithCtx.key.tabId === this.tabId) {
            this.dataContainers.forEach((datacontainer) => {
              if (!columnName || datacontainer.data.columnName !== columnName) {
                datacontainer.setNewData(dataWithCtx);
              }
            });
          }
        });
      } else {
        this.dataStored = dataStored;
        const istabOpenedFromAnotherTab =
          this.editViewParent &&
          this.editViewParent.linkedComponents &&
          this.editViewParent.linkedComponents[0] &&
          this.editViewParent.linkedComponents[0].data &&
          this.editViewParent.linkedComponents[0].data.isSingleRow;
        if ((dataStored.key && dataStored.key.tabId === this.tabId) || istabOpenedFromAnotherTab) {
          this.dataContainers.forEach((datacontainer) => {
            if (!columnName || datacontainer.data.columnName !== columnName) {
              datacontainer.setNewData(dataStored);
            }
          });
        }
      }
    }
  }

  checkForCopy(copiedData: DataStore) {
    this.dataContainers
      .filter(
        (dataContainer) => !(dataContainer instanceof AccordionUiComponent) || !(dataContainer instanceof EditTabUiComponent)
      )
      .forEach((dataContainer) => {
        if (dataContainer.data.isCopy === undefined || dataContainer.data.isCopy === false) {
          copiedData.data[dataContainer.data.columnName] = dataContainer.isSwitchField
            ? 'N'
            : dataContainer.isMoneyField
            ? 0
            : null;
        }
      });

    Object.keys(copiedData.data).forEach((columnName) => {
      if (
        columnName === 'Processed' ||
        columnName === 'Processing' ||
        columnName === 'IsApproved' ||
        columnName === 'IsDelivered' ||
        columnName === 'IsInvoiced' ||
        columnName === 'IsPaid' ||
        columnName === 'IsAllocated' ||
        columnName === 'Posted' ||
        columnName === 'I_IsImported'
      ) {
        copiedData.data[columnName] = 'N';
      } else if (columnName === 'DocumentNo' || columnName === 'C_Location_ID' || columnName === 'Value') {
        copiedData.data[columnName] = null;
      } else if (columnName === 'DocStatus') {
        copiedData.data[columnName] = 'DR';
      } else if (columnName === 'DocAction') {
        copiedData.data[columnName] = 'CO';
      } else if (
        columnName === 'GrandTotal' ||
        columnName === 'TotalLines' ||
        columnName === 'TotalDr' ||
        columnName === 'TotalCr'
      ) {
        copiedData.data[columnName] = 0;
      }
    });
    return copiedData;
  }

  isConstraintMandatoryRespected(): string[] {
    const isOk = [];
    if (this.dataContainers) {
      let i = 0;
      while (isOk && i < this.dataContainers.length) {
        if (
          !this.dataContainers[i].isAccordion &&
          this.dataContainers[i].displayCss !== 'none' &&
          this.dataContainers[i].data.isMandatory &&
          (this.dataContainers[i].fieldValue === undefined ||
            this.dataContainers[i].fieldValue === null ||
            this.dataContainers[i].fieldValue === '')
        ) {
          isOk.push(this.dataContainers[i].label);
        }
        i++;
      }
    }
    return isOk;
  }
  refreshGrid() {
    if (!this.isCollapsed && this.children[0].component === 'GridViewUiComponent' && this.DOMChildrenComponent[0]) {
      (<GridViewUiComponent>this.DOMChildrenComponent[0]).refreshGrid();
    }
  }
  onSiblingUpdate(event: IupicsEvent) {
    if (event.type === IupicsTypeEvent.collapseEvent) {
      if (this.editViewParent && this.editViewParent.editTabs && this.editViewParent.editTabs.length > 1) {
        this.editViewParent.editTabs.forEach((tab) => {
          tab.collaspTab();
        });
        this.editViewParent.smartButtons.resizeSmartButton(IupicsTypeEvent.collapseEvent);
      }
    } else if (event.type === IupicsTypeEvent.expandEvent) {
      if (this.editViewParent && this.editViewParent.editTabs && this.editViewParent.editTabs.length > 1) {
        this.editViewParent.editTabs.forEach((tab) => {
          tab.expandTab();
        });
        this.editViewParent.smartButtons.resizeSmartButton(IupicsTypeEvent.expandEvent);
      }
    }
  }
  onRemoveComponent(event: IupicsEvent) {}
  ngAfterViewInit() {
    super.ngAfterViewInit();
    const request: DataStoreRequest = {
      windowId: this.dataStoreKey.windowId,
      record_id: this.dataStoreKey.recordId,
      parent_constraint: this.dataStoreKey.parentId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.dataStoreKey.tabId,
        startRow: 0,
        endRow: 1
      }
    };
    this.subscriptions.push(
      this.store.getWindowSingleData(request).subscribe((dataStored) => {
        this.dataStored = dataStored;
        if (this.children[0].component === 'SelectOrderComponent') {
          this.children[0].data.RecordID = this.dataStored.key.recordId;
        }
        // check if its new
        if (!this.dataStoreKey) {
          this.store.newWindowData(
            this.dataStored.key.windowId,
            this.tabId,
            this.dataStored.key.recordId,
            EditViewUtils.getParentDatastoreKeyFromTab(this)
          );
        }
        // check si le tab doit etre affiché ou non
        this.updateAllLogic(dataStored);
        // check si il faut déjà créer les enfants
        if (!this.collapsed) {
          this.buildChildren();
          this.childrenCreated = true;
        } else {
          if (this.isEditTabShouldOpen() && (!this.data.isSingleRow || this.isReadOnly)) {
            this.collapsed = false;
          } else {
            this.isExpand = false;
          }
        }
        if (
          this.data.isSingleRow &&
          this.container &&
          this.container.activeTab &&
          this.container.activeTab.othersRecordId &&
          this.container.activeTab.othersRecordId.length > 0
        ) {
          if (this.container.activeTab.othersRecordId[0].tabId == this.tabId) {
            this.openInNewBlade();
            this.container.activeTab.othersRecordId.splice(0, 1);
            if (this.container.activeTab.othersRecordId.length === 0) {
              this.container.activeTab.othersRecordId = undefined;
            }
          }
        }
      })
    );
  }
  isEditTabShouldOpen() {
    let shouldOpenForZoomOrUrl = false;
    if (this.editViewParent.zoomInfo && this.editViewParent.zoomInfo.children) {
      const searchedElement = this.editViewParent.zoomInfo.children[this.editViewParent.zoomInfo.children.length - 1];
      if (searchedElement && this.children && this.children.length > 0 && this.children[0].data) {
        if (searchedElement.Tab_ID == this.children[0].data.ADTabID) {
          shouldOpenForZoomOrUrl = true;
        }
      }
    }
    if (this.container.activeTab.othersRecordId && this.container.activeTab.othersRecordId.length > 0) {
      const searchedElement = this.container.activeTab.othersRecordId[0];
      if (searchedElement && this.children && this.children.length > 0 && this.children[0].data) {
        if (searchedElement.tabId == this.children[0].data.ADTabID) {
          shouldOpenForZoomOrUrl = true;
        }
      }
    }
    return shouldOpenForZoomOrUrl;
  }

  buildChildren() {
    /*pour permettre l'affichage instantané des rows */
    if (this.gridTabFilter && this.data.isSingleRow && this.isTopEditTab) {
      const mapfilter = LogicEvaluator.parseLogic(
        this.dataStored.data,
        this.gridTabFilter[0],
        this.connectorService.getIupicsUserContext()
      );
      const dataStoreRequest: DataStoreRequest = {
        windowId: this.dataStored.key.windowId,
        record_id: this.dataStored.key.recordId,
        parent_constraint: mapfilter,
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: this.tabId,
          startRow: 0,
          endRow: 1
        }
      };
      //check si on pointe sur la meme table que l'onglet 0
      if (
        this.data.isSingleRow &&
        this.data.TableName &&
        this.editViewParent?.linkedComponents[0]?.editViewParent?.data?.TableName === this.data.TableName
      ) {
        dataStoreRequest.parent_constraint = '';
        dataStoreRequest.compiereRequest.entityId = this.editViewParent.linkedComponents[0].editViewParent.tabId;
      }
      this.subscriptions.push(
        this.store.getWindowSingleData(dataStoreRequest).subscribe((data) => {
          this.dataStored = data;
          this.children.forEach((child) => {
            child.parentTab = this;
            if (child.data !== undefined) {
              child.data.isTabTopLevel = false; // pour dire que les enfants n'active pas les keybind
            }
            WindowFactoryUtils.addContainerComponent(
              this,
              child,
              this.resolver,
              child.container ? child.isCssOnComponent : false
            );
          });
        })
      );
    } else {
      this.children.forEach((child) => {
        child.parentTab = this;
        if (child.data !== undefined) {
          child.data.isTabTopLevel = false; // pour dire que les enfants n'active pas les keybind
        }
        WindowFactoryUtils.addContainerComponent(this, child, this.resolver, child.container ? child.isCssOnComponent : false);
      });
    }
  }

  openInNewBlade() {
    const linkedComponent = this;
    const item: DynamicComponent = {
      container: this.container,
      DOMParentComponent: this.container,
      linkedComponents: [linkedComponent],
      component: 'EditViewUiComponent',
      cssClass: 'iupics-blade-content',
      isCssOnComponent: false,
      tabId: this.tabId
    };
    this.componentEmitter.emit({
      type: IupicsTypeEvent.showEditView,
      item: item
    });

    setTimeout(() => {
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.selectDataChange,
        item: {
          container: null,
          dataStoreKey: this.dataStoreKey
        }
      });
    }, 0);
  }
  scrollTo() {
    if (this.editViewParent) {
      this.editViewParent.goToAnchor(new MouseEvent('click'), this);
    }
  }
  /**
   * mise à jour du display
   * @param dataStore nouveau datastore à prendre en compte
   */
  updateDisplayLogic(dataStore?: DataStore) {
    let isDisplayed = true;
    let displayCss: 'inline' | 'none' = 'inline';
    if (!this.isTopEditTab) {
      let displayLogic;
      if (this.children && this.children[0].component === 'GridViewUiComponent' && this.data && !this.data.isSingleRow) {
        // displayLogic de la grille
        displayLogic = this.children[0].data.DisplayLogic;
      } else {
        // displayLogic du tab
        displayLogic = this.data ? this.data.DisplayLogic : null;
      }
      if (displayLogic) {
        const currentContext = this.getCurrentContext(dataStore ? dataStore : this.dataStored);
        isDisplayed = LogicEvaluator.evaluateLogic(currentContext ? currentContext : null, displayLogic);
      }
      displayCss = isDisplayed ? 'inline' : 'none';
    } else {
      displayCss = 'inline';
    }
    if (this.isDisplay !== displayCss) {
      this.isDisplay = displayCss;
    }
    return isDisplayed;
  }
  /**
   * mise à jour du readOnly
   * @param dataStore nouveau datastore à prendre en compte
   */
  updateReadOnlyLogic(dataStore?: DataStore) {
    const readOnlyLogic = this.data.ReadOnlyLogic;
    if (readOnlyLogic) {
      if (EditViewUtils.checkLogic(readOnlyLogic, this.getCurrentContext(dataStore ? dataStore : this.dataStored))) {
        this.isReadOnly = true;
      } else {
        this.isReadOnly = false;
      }
      if (this.children[0] && this.children[0].component === 'GridViewUiComponent' && this.DOMChildrenComponent[0]) {
        this.DOMChildrenComponent[0].IsReadOnly = this.isReadOnly === undefined ? false : this.isReadOnly;
      }
    }
    if (this.isTopEditTab && this.editViewParent && this.editViewParent.IsReadOnly === false) {
      this.editViewParent.IsReadOnly = this.isReadOnly === undefined ? false : this.isReadOnly;
    }
    return this.isReadOnly;
  }
  /**
   * mise à jour du IsDeletable
   * @param dataStore nouveau datastore à prendre en compte
   */
  updateIsDeleteableOnlyLogic(dataStore?: DataStore) {
    const isDeletableLogic = this.data.IsDeletableLogic;
    if (isDeletableLogic && this.children[0]) {
      if (EditViewUtils.checkLogic(isDeletableLogic, this.getCurrentContext(dataStore ? dataStore : this.dataStored))) {
        this.IsDeleteable = true;
      } else {
        this.IsDeleteable = false;
      }
      if (this.children[0] && this.children[0].component === 'GridViewUiComponent' && this.DOMChildrenComponent[0]) {
        this.DOMChildrenComponent[0].IsDeleteable = this.IsDeleteable === undefined ? true : this.IsDeleteable;
      }
      if (this.isTopEditTab && this.editViewParent && this.editViewParent.IsDeleteable === true) {
        this.editViewParent.IsDeleteable = this.IsDeleteable === undefined ? true : this.IsDeleteable;
      }
    }
  }
  updateAllLogic(datastore?: DataStore) {
    this.updateDisplayLogic(datastore);
    this.updateReadOnlyLogic(datastore);
    this.updateIsDeleteableOnlyLogic(datastore);
  }
}
