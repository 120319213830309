import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridType,
  DataStore,
  DataStoreRequest
} from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { CompiereWorkflowService } from '@compiere-ws/services/compiere-workflow/compiere-workflow.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItem, CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { InputNumberUiComponent } from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import {
  ActiveTabEventType,
  ActiveTabManagerService
} from '@iupics-manager/managers/active-tab-manager/active-tab-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, debounce, has, isNil, isObject } from 'lodash';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subscription, zip } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as shajs from 'sha.js';
import { ProcessParams } from '../process-ui/process-ui.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-fast-create-order',
  templateUrl: './fast-create-order.component.html',
  styleUrls: ['./fast-create-order.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FastCreateOrderComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    windowFactory: WindowFactoryService,
    resolver: ComponentFactoryResolver,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService,
    socketService: SocketService,
    protected translateService: TranslateService,
    private poService: PoService,
    private workflowService: CompiereWorkflowService,
    private messageManager: MessageManagerService,
    private messageService: MessageService,
    private activeTabManager: ActiveTabManagerService
  ) {
    super(
      windowFactory,
      resolver,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.saveOrder = debounce(this.saveOrder, 1000, { leading: true, trailing: true });
    this.saveOrderLine = debounce(this.saveOrderLine, 1000, { leading: true, trailing: true });
    this.docStatus = this.translateService.instant('specificWindow.quickOrder.new');
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: 'vcrBPartner',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_ID',
        cssClass: 'p-col-12',
        isLabelDisplay: false
      },
      {
        vcr: 'vcrDocType',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_DocTypeTarget_ID',
        cssClass: 'p-col-12',
        isLabelDisplay: false
      },
      {
        vcr: 'vcrPaymentRule',
        type: CustomDesignItemType.FIELD,
        columnName: 'PaymentRule',
        cssClass: 'p-col-12',
        isLabelDisplay: false
      },
      {
        vcr: 'vcrMPriceList',
        type: CustomDesignItemType.FIELD,
        columnName: 'M_PriceList_ID',
        cssClass: 'p-col-12',
        isLabelDisplay: false
      },
      {
        vcr: 'vcrBPartnerLocation',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_Location_ID',
        cssClass: 'p-col-12',
        isLabelDisplay: false
      },
      {
        vcr: 'vcrOrder',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_Order_ID',
        cssClass: 'p-col-12',
        isLabelDisplay: false
      },
      {
        vcr: 'vcrPOReference',
        type: CustomDesignItemType.FIELD,
        columnName: 'POReference',
        cssClass: 'p-col-12',
        isLabelDisplay: false
      },
      {
        vcr: 'vcrDateOrdered',
        type: CustomDesignItemType.FIELD,
        columnName: 'DateOrdered',
        cssClass: 'p-col-12',
        isLabelDisplay: false
      },
      {
        vcr: 'vcrGrandTotal',
        type: CustomDesignItemType.FIELD,
        columnName: 'GrandTotal',
        cssClass: 'p-col-12',
        isLabelDisplay: false
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_Org_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'DeliveryViaRule',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_DocType_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'DocAction',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'SalesRep_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'InvoiceRule',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'M_Shipper_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'IsSOTrx',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'IsReturnTrx',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'IsReleaseDocument',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'isQuote',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'DeliveryRule',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_PaymentTerm_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'Bill_Location_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'Bill_User_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'M_Warehouse_ID',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrSettings',
        type: CustomDesignItemType.FIELD,
        columnName: 'OrderType',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcrProcessOrder',
        type: CustomDesignItemType.FIELD,
        columnName: 'Processing',
        cssClass: 'p-col-12'
      }
    );
  }
  @ViewChild('vcrBPartner', { read: ViewContainerRef, static: true })
  vcrBPartner: ViewContainerRef;
  @ViewChild('vcrProcessOrder', { read: ViewContainerRef, static: true })
  vcrProcessOrder: ViewContainerRef;

  // @ViewChild('vcrDeliveryRule', { read: ViewContainerRef, static: true })
  // vcrDeliveryRule: ViewContainerRef;
  @ViewChild('vcrPaymentRule', { read: ViewContainerRef, static: true })
  vcrPaymentRule: ViewContainerRef;

  // @ViewChild('vcrDeliveryViaRule', { read: ViewContainerRef, static: true })
  // vcrDeliveryViaRule: ViewContainerRef;
  @ViewChild('vcrDocType', { read: ViewContainerRef, static: true })
  vcrDocType: ViewContainerRef;

  // @ViewChild('vcrMShipper', { read: ViewContainerRef, static: true })
  // vcrMShipper: ViewContainerRef;
  @ViewChild('vcrMPriceList', { read: ViewContainerRef, static: true })
  vcrMPriceList: ViewContainerRef;

  @ViewChild('vcrBPartnerLocation', { read: ViewContainerRef, static: true })
  vcrBPartnerLocation: ViewContainerRef;

  @ViewChild('vcrOrder', { read: ViewContainerRef, static: true })
  vcrOrder: ViewContainerRef;

  @ViewChild('vcrPOReference', { read: ViewContainerRef, static: true })
  vcrPOReference: ViewContainerRef;
  @ViewChild('vcrDateOrdered', { read: ViewContainerRef, static: true })
  vcrDateOrdered: ViewContainerRef;

  @ViewChildren('vcrProduct', { read: ViewContainerRef })
  vcrsProduct: QueryList<ViewContainerRef>;

  @ViewChildren('vcrQty', { read: ViewContainerRef })
  vcrsQty: QueryList<ViewContainerRef>;

  @ViewChildren('vcrDescription', { read: ViewContainerRef })
  vcrsDescription: QueryList<ViewContainerRef>;

  @ViewChildren('vcrQtyOnHand', { read: ViewContainerRef })
  vcrsQtyOnHand: QueryList<ViewContainerRef>;

  @ViewChildren('vcrPriceEntered', { read: ViewContainerRef })
  vcrsPriceEntered: QueryList<ViewContainerRef>;

  @ViewChildren('vcrPriceActual', { read: ViewContainerRef })
  vcrsPriceActual: QueryList<ViewContainerRef>;
  @ViewChildren('vcrPriceList', { read: ViewContainerRef })
  vcrsPriceList: QueryList<ViewContainerRef>;

  @ViewChildren('vcrLineTotalAmt', { read: ViewContainerRef })
  vcrsLineTotalAmt: QueryList<ViewContainerRef>;

  @ViewChildren('vcrDiscount', { read: ViewContainerRef })
  vcrsDiscount: QueryList<ViewContainerRef>;

  @ViewChild('vcrGrandTotal', { read: ViewContainerRef, static: true })
  vcrGrandTotal: ViewContainerRef;

  @ViewChild('vcrSettings', { read: ViewContainerRef, static: true })
  vcrSettings: ViewContainerRef;

  // #147916 brgin
  saving = false;
  isSidebarOpen = false;
  isrefreshing = false;
  // #147916 end

  subscription: Subscription;
  docStatus = '*';
  lineMetaData: CustomDesignItem[] = [
    {
      vcr: 'vcrsProduct',
      type: CustomDesignItemType.FIELD,
      columnName: 'M_Product_ID',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsQty',
      type: CustomDesignItemType.FIELD,
      columnName: 'QtyEntered',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsDescription',
      type: CustomDesignItemType.FIELD,
      columnName: 'Description',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsQtyOnHand',
      type: CustomDesignItemType.FIELD,
      columnName: 'QtyOnHand',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsPriceEntered',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceEntered',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsPriceActual',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceActual',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsPriceList',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceList',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsDiscount',
      type: CustomDesignItemType.FIELD,
      columnName: 'Discount',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsLineTotalAmt',
      type: CustomDesignItemType.FIELD,
      columnName: 'LineNetAmt',
      cssClass: 'no-class',
      isLabelDisplay: false
    }
  ];
  MANDATORY_HEADER_COLUMNS = [
    'C_BPartner_ID',
    'C_BPartner_Location_ID',
    'DateOrdered',
    'AD_Org_ID',
    'C_DocTypeTarget_ID',
    'M_PriceList_ID',
    'SalesRep_ID',
    'M_Warehouse_ID'
  ];
  CALLOUTS_HEADER_COLUMNNAMES = [
    'C_BPartner_Location_ID',
    'Bill_BPartner_ID',
    'C_BPartner_ID',
    'Bill_Location_ID',
    'M_Warehouse_ID',
    'DateOrdered',
    'C_DocTypeTarget_ID',
    'M_PriceList_ID'
  ];
  dataLines: DataStore[] = [];
  dataContainersLine: AbstractDataContainer[][] = [];
  tabindex = 1;

  popUpKey: string;
  popUpData: any;

  private orderTabID: number;
  private orderLineTabID: number;
  private activeTabListener: () => void = () => {};
  ngOnInit() {
    super.ngOnInit();
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item
    });
    this.popUpKey = shajs('sha256')
      .update('global' + moment().valueOf())
      .digest('hex');

    // #147916 remove refresh when switching tabs
    // this.activeTabListener = this.activeTabManager.listen(this.activeTab, (event) => {
    //   if (event.type === ActiveTabEventType.TAB_ACTIVE || event.type === ActiveTabEventType.TAB_GROUP_ACTIVE) {
    //     this.refreshOrder();
    //   }
    // });
    zip(this.getTabID('Order_186'), this.getTabID('Order Line_187')).subscribe(([orderTabIDResponse, orderLineTabIDResponse]) => {
      if (orderTabIDResponse.data.length) {
        this.orderTabID = orderTabIDResponse.data[0]['AD_Tab_ID'];
      } else {
        throw new IupicsMessage('Error', 'Error getting tab id of "Order_186"');
      }

      if (orderLineTabIDResponse.data.length) {
        this.orderLineTabID = orderLineTabIDResponse.data[0]['AD_Tab_ID'];
      } else {
        throw new IupicsMessage('Error', 'Error getting tab id of "Order Line_187"');
      }
    });
  }

  private getTabID(value: string) {
    return this.store.getDataGrid({
      compiereRequest: {
        windowType: CompiereDataGridType.TABLE,
        tableName: 'AD_Tab',
        endRow: 1,
        startRow: 0,
        filterModel: {
          Value: {
            filterType: CompiereDataGridFilterType.TEXT,
            operators: [OperatorFilterType.EQUALS],
            values: [value]
          }
        },
        headerCols: [{ displayName: 'AD_Tab_ID', field: 'AD_Tab_ID', id: 'AD_Tab_ID' }]
      },
      windowId: -1
    });
  }

  ngAfterViewInit() {
    this.dataStore.dataChange.subscribe((dataChanged) => {
      const keys = Object.keys(dataChanged.dataModified);
      for (let i = 0; i < keys.length; i++) {
        const columnName = keys[i];
        this.notifyFromDataChange({ data: { columnName } });
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.activeTabListener();
  }
  /**
   * Overrided to avoid std behaviour of datacontainer in specific window
   */
  setDataContainersValueWithChangedStore(dataStore?: DataStore) {}
  calloutColumn(columnName: string) {
    if (this.dataStore && this.dataStore.data[columnName] !== null && this.dataStore.data[columnName] !== undefined) {
      if (columnName.toUpperCase() == 'C_BPARTNER_LOCATION_ID') {
      }
      const value = this.dataStore.data[columnName].id ? this.dataStore.data[columnName].id : this.dataStore.data[columnName];
      this.store.calloutData(
        '/Field/Callout/C_Order/' + columnName,
        {
          columnName: columnName,
          newValue: value,
          windowCtx: this.getCurrentContext()
        },
        this.dataStore,
        null,
        () => {
          this.saveOrder();
        }
      );
    } else {
      this.saveOrder();
    }
  }
  calloutPartner() {
    const newValue = has(this.dataStore.data, 'C_BPartner_ID.id')
      ? this.dataStore.data['C_BPartner_ID'].id
      : this.dataStore.data['C_BPartner_ID'];
    this.store.calloutData(
      '/Field/Callout/C_Order/C_BPartner_ID',
      {
        columnName: 'C_BPartner_ID',
        newValue,
        windowCtx: this.getCurrentContext()
      },
      this.dataStore,
      null,
      () => {
        this.saveOrder();
      }
    );
  }

  calloutDiscount(lineDataStore: DataStore) {
    this.store.calloutData(
      '/Field/Callout/C_OrderLine/Discount',
      {
        columnName: 'Discount',
        newValue: lineDataStore.data['Discount'],
        windowCtx: this.getCurrentContext(lineDataStore, this.dataStore)
      },
      lineDataStore,
      null,
      () => {
        this.saveOrderLine(lineDataStore);
      }
    );
  }

  calloutProduct(lineDataStore: DataStore, fromNew = false) {
    this.store.calloutData(
      '/Field/Callout/C_OrderLine/M_Product_ID',
      {
        columnName: 'M_Product_ID',
        newValue:
          lineDataStore.data['M_Product_ID'] instanceof Object
            ? lineDataStore.data['M_Product_ID'].id
            : lineDataStore.data['M_Product_ID'],
        windowCtx: this.getCurrentContext(lineDataStore, this.dataStore)
      },
      lineDataStore,
      null,
      !fromNew
        ? () => {
          this.saveOrderLine(lineDataStore);
        }
        : () => {}
    );
    this.subscription?.unsubscribe();
    const product_request: DataStoreRequest = {
      windowId: null,
      parent_constraint: null,
      compiereRequest: {
        startRow: 0,
        tableName: 'M_Product',
        filterModel: {
          M_Product_ID: {
            filterType: CompiereDataGridFilterType.SET,
            values: [lineDataStore.data['M_Product_ID'].id],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };

    const ctx = this.connectorService.getIupicsUserContext();

    const qty_request: DataStoreRequest = {
      windowId: null,
      parent_constraint: null,
      compiereRequest: {
        startRow: 0,
        tableName: 'M_StorageDetail',
        valueCols: [
          {
            displayName: 'Qty',
            field: 'Qty',
            id: 'Qty',
            aggFunc: 'sum'
          }
        ],
        filterModel: {
          M_Product_ID: {
            filterType: CompiereDataGridFilterType.SET,
            values: [lineDataStore.data['M_Product_ID'].id],
            operators: [OperatorFilterType.EQUALS]
          },
          AD_Org_ID: {
            filterType: CompiereDataGridFilterType.SET,
            values: [ctx['#AD_Org_ID']],
            operators: [OperatorFilterType.EQUALS]
          },
          QtyType: {
            filterType: CompiereDataGridFilterType.TEXT,
            values: ['H'],
            operators: [OperatorFilterType.EQUALS]
          }
        }
      }
    };

    const sub = zip(this.store.getDataGrid(product_request, true), this.store.getDataGrid(qty_request, true)).subscribe(
      ([productResponse, qtyResponse]) => {
        this.store.syncDataChanges(lineDataStore, {
          Description: productResponse.data[0]['DESCRIPTION'],
          QtyOnHand: qtyResponse.data[0]['QTY']
        });
        sub.unsubscribe();
      }
    );
  }

  calloutQty(lineDataStore: DataStore) {
    this.store.calloutData(
      '/Field/Callout/C_OrderLine/QtyEntered',
      {
        columnName: 'QtyEntered',
        newValue: lineDataStore.data['QtyEntered'],
        windowCtx: this.getCurrentContext(lineDataStore, this.dataStore)
      },
      lineDataStore,
      null,
      () => {
        this.saveOrderLine(lineDataStore);
      }
    );
  }
  calloutPriceActual(lineDataStore: DataStore) {
    this.store.calloutData(
      '/Field/Callout/C_OrderLine/PriceActual',
      {
        columnName: 'PriceActual',
        newValue: lineDataStore.data['PriceActual'],
        windowCtx: this.getCurrentContext(lineDataStore, this.dataStore)
      },
      lineDataStore,
      null,
      () => {
        this.saveOrderLine(lineDataStore);
      }
    );
  }
  calloutPriceList(lineDataStore: DataStore) {
    this.store.calloutData(
      '/Field/Callout/C_OrderLine/PriceList',
      {
        columnName: 'PriceList',
        newValue: lineDataStore.data['PriceList'],
        windowCtx: this.getCurrentContext(lineDataStore, this.dataStore)
      },
      lineDataStore,
      null,
      () => {
        this.saveOrderLine(lineDataStore);
      }
    );
  }
  calloutPriceEntered(lineDataStore: DataStore) {
    this.store.calloutData(
      '/Field/Callout/C_OrderLine/PriceEntered',
      {
        columnName: 'PriceEntered',
        newValue: lineDataStore.data['PriceEntered'],
        windowCtx: this.getCurrentContext(lineDataStore, this.dataStore)
      },
      lineDataStore,
      null,
      () => {
        this.saveOrderLine(lineDataStore);
      }
    );
  }

  saveOrder() {
    const nullValue = this.getNullValueInData(this.dataStore.data, this.MANDATORY_HEADER_COLUMNS);
    if (
      !nullValue &&
      this.dataStore.data['C_BPartner_ID'] != null &&
      this.dataStore.data['C_BPartner_Location_ID'] instanceof Object &&
      this.dataStore.data['AD_Org_ID'] &&
      this.dataStore.data['AD_Org_ID'].id > 0 &&
      this.dataStore.data['SalesRep_ID'] != null
    ) {

      const id =
        this.dataStore.data['C_Order_ID'] instanceof Object
          ? this.dataStore.data['C_Order_ID'].id
          : this.dataStore.data['C_Order_ID'];
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = undefined;
      }
      // #147916 begin
      this.saving = true;
      this.subscription = this.store
        .saveSpecificWindowData(this.dataStore, this.orderTabID, ['C_Order_ID'], this.getCurrentContext())
        .subscribe((event) => {
          if (event != null) {
            this.saving = false;
          } else if (id != null) {
            this.refreshOrder();
          }
        });
    } else {
      const isNew = this.dataStore.data['C_Order_ID'] == null;
      // begin 147916 open sidebar when missing data
      const show_Sidebar = this.dataStore.data['C_BPartner_ID'] != null && this.dataStore.data['C_BPartner_Location_ID'] != null &&
        this.dataStore.data['C_DocTypeTarget_ID'] != null && this.dataStore.data['M_PriceList_ID'] != null &&
        ['AD_Org_ID', 'SalesRep_ID', 'C_PaymentTerm_ID', 'Bill_Location_ID', 'M_Warehouse_ID'].includes(nullValue);

      if (show_Sidebar) {
        this.isSidebarOpen = true;
      }

      if ((nullValue && !isNew) || show_Sidebar) {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translateService.instant('generic.warning'),
            this.translateService.instant('specificWindow.quickOrder.missingParams') +
            ' : ' + nullValue,
            'error'
          )
        );
      }
    }
    // #147916 end
  }

  /*
    private isOrderHeaderOK(): boolean {
      // check dataStore
      if (!this.dataStore || !this.dataStore.data) {
        return false;
      }
      // check C_BPartner_ID
      if (!has(this.dataStore.data, 'C_BPartner_ID.id') || this.dataStore.data['C_BPartner_ID'].id <= 0) {
        return false;
      }
      // check C_BPartner_Location_ID
      if (!has(this.dataStore.data, 'C_BPartner_Location_ID.id') || this.dataStore.data['C_BPartner_Location_ID'].id <= 0) {
        return false;
      }
      // check AD_Org_ID
      if (!has(this.dataStore.data, 'AD_Org_ID.id') || this.dataStore.data['AD_Org_ID'].id <= 0) {
        return false;
      }
      // check C_DocTypeTarget_ID
      if (!has(this.dataStore.data, 'C_DocTypeTarget_ID.id') || this.dataStore.data['C_DocTypeTarget_ID'].id <= 0) {
        return false;
      }
      // check SalesRep_ID
      if (!has(this.dataStore.data, 'SalesRep_ID.id') || this.dataStore.data['SalesRep_ID'].id <= 0) {
        return false;
      }
      // check PaymentRule
      if (!has(this.dataStore.data, 'PaymentRule.id') || this.dataStore.data['PaymentRule'].id <= 0) {
        return false;
      }
      // check M_PriceList_ID
      if (!has(this.dataStore.data, 'M_PriceList_ID.id') || this.dataStore.data['M_PriceList_ID'].id <= 0) {
        return false;
      }
      return true;
    }
  */

  private saveOrderLineInStore(datastore: DataStore, id?: number) {
    const tmpStore = new DataStore();
    tmpStore.data = this.getCurrentContext();
    const dataReformat = Object.assign({}, this.getCurrentContext(datastore, tmpStore));
    Object.keys(dataReformat).forEach((key) => {
      if (dataReformat[key] instanceof Object) {
        dataReformat[key] = dataReformat[key].id;
      }
    });
    return this.poService.save('C_OrderLine', dataReformat, id);
  }
  saveOrderLine(lineDataStore: DataStore) {
    const isNew = lineDataStore.data['C_OrderLine_ID'] == null;
    if (
      (lineDataStore.data['M_Product_ID'] instanceof Object || lineDataStore.data['C_Charge_ID'] instanceof Object) &&
      lineDataStore.data['QtyEntered'] != null &&
      (isNew || (lineDataStore.data['PriceEntered'] != null && lineDataStore.data['C_Tax_ID'] != null))
    ) {
      const id =
        lineDataStore.data['C_OrderLine_ID'] instanceof Object
          ? lineDataStore.data['C_OrderLine_ID'].id
          : lineDataStore.data['C_OrderLine_ID'];
      // todo: trouver une meilleure façon de créer les datastore pour avoir les bonnes données lors de la sauvegarde via le dataService
      // this.store
      //   .saveSpecificWindowData(
      //     lineDataStore,
      //     this.orderLineTabID,
      //     ['C_OrderLine_ID'],
      //     this.getCurrentContext(lineDataStore, this.dataStore)
      //   )
      //   .subscribe();
      // #147916 begin
      if (this.saving) {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translateService.instant('generic.error'),
            'Order is not saved !',
            'error'
          )
        );
      } else {
        this.saveOrderLineInStore(lineDataStore, id).subscribe((result) => {
          // if new we should use callout again because record_id is needed
          lineDataStore.data['C_OrderLine_ID'] = result['C_OrderLine_ID'];
          if (isNew) {
            if (lineDataStore.data['M_Product_ID'] instanceof Object) {
              this.calloutProduct(lineDataStore, true);
            }
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.success'),
                this.translateService.instant('specificWindow.quickOrder.saveLineSuccess'),
                'success'
              )
            );
          } else {
            this.store.syncDataChanges(lineDataStore, result, false, true);
          }
        });
      }
      // #147916 end
    }
  }
  notifyFromDataChange(item: any, vcrIndex?: number) {
    if (!this.isrefreshing) {
      let lineDataStore = null;
      if (vcrIndex !== undefined) {
        lineDataStore = this.dataLines[vcrIndex];
      }

      if (item.data['columnName'] === 'C_BPartner_ID' && this.dataStore && this.dataStore.data['C_BPartner_ID']) {
        this.calloutPartner();
      } else if (
        this.CALLOUTS_HEADER_COLUMNNAMES.includes(item.data['columnName']) &&
        this.dataStore &&
        this.dataStore.data[item.data['columnName']]
      ) {
        this.calloutColumn(item.data['columnName']);
      } else if (item.data['columnName'] === 'M_Product_ID' && lineDataStore && lineDataStore.data['M_Product_ID']) {
        this.calloutProduct(lineDataStore);
      } else if (item.data['columnName'] === 'QtyEntered' && lineDataStore) {
        // #147916 Fixed a bug when deleting QtyEntered, you can no longer modify the line again
        if (!lineDataStore.data['QtyEntered']) {
          lineDataStore.data['QtyEntered'] = 0;
        }
        this.calloutQty(lineDataStore);
      } else if (item.data['columnName'] === 'Discount' && lineDataStore) {
        // #147916 Fixed a bug can't set Discount to 0
        // #147916 Fixed a bug when deleting Discount, you can no longer modify the line again
        if (!lineDataStore.data['Discount']) {
          lineDataStore.data['Discount'] = 0;
        }
        this.calloutDiscount(lineDataStore);
      } else if (item.data['columnName'] === 'PriceActual' && lineDataStore && lineDataStore.data['PriceActual']) {
        this.calloutPriceActual(lineDataStore);
      } else if (item.data['columnName'] === 'PriceList' && lineDataStore && lineDataStore.data['PriceList']) {
        this.calloutPriceList(lineDataStore);
      } else if (item.data['columnName'] === 'PriceEntered' && lineDataStore && lineDataStore.data['PriceEntered']) {
        this.calloutPriceEntered(lineDataStore);
      }
      if (item.data['columnName'] === 'C_Order_ID' && this.dataStore.data['C_Order_ID'] instanceof Object) {
        this.docStatus = this.translateService.instant('specificWindow.quickOrder.draft');
        this.newLine(true);
      }

      if ( // #147916
        (item.data['columnName'] != 'C_BPartner_Location_ID' && this.CALLOUTS_HEADER_COLUMNNAMES.includes(item.data['columnName']) &&
          !this.dataStore.data[item.data['columnName']]) ||
        ['AD_Org_ID', 'SalesRep_ID', 'DeliveryViaRule', 'M_Shipper_ID', 'C_PaymentTerm_ID',
          'Bill_User_ID', 'POReference', 'Bill_Location_ID', 'InvoiceRule', 'IsSOTrx', 'isQuote',
          'DeliveryRule', 'PaymentRule'].includes(
          item.data['columnName']
        )
      ) {
        this.saveOrder();
      }
      // #147916 fixed a bug that duplicates lines
      // if (lineDataStore && ['M_Product_ID', 'QtyEntered', 'Discount'].includes(item.data['columnName'])) {
      //   if (!lineDataStore.data['C_OrderLine_ID']) {
      //     this.saveOrderLine(lineDataStore);
      //   }
      // }
    }
  }

  /**
   * Permet d'ajouter visuellement une nouvelle ligne de commande + création d'un nouveau datastore
   */
  newLine(isEmpty = false, _dataStored?: DataStore) {
    if (
      isEmpty ||
      (this.dataStore &&
        this.dataStore.data['DocStatus'] &&
        (this.dataStore.data['DocStatus'] !== 'CO' || this.dataStore.data['DocStatus'].id !== 'CO'))
    ) {
      this.dataLines.push(_dataStored || this.store.newSpecificWindowData(this.formId));
      this.dataLines[this.dataLines.length - 1].data['Line'] = this.dataLines.length * 10;

      this.subscriptions.push(
        this.dataLines[this.dataLines.length - 1].dataChange.subscribe((dataChanged) => {
          let grandTotal = 0;
          this.dataLines.forEach((line) => {
            grandTotal += line.data['LineNetAmt'];
          });
          this.store.syncDataChanges(this.dataStore, { GrandTotal: Math.round((grandTotal + Number.EPSILON) * 100) / 100 });
        })
      );

      this.lineMetaData.forEach((metaData) => {
        const compRef = this.createCustomDesignItem(metaData, true, this.dataLines.length - 1);
        if (compRef) {
          if (metaData.columnName !== 'Description') {
            compRef.location.nativeElement.setAttribute('tabindex', this.tabindex++);
          } else {
            compRef.location.nativeElement.setAttribute('tabindex', -1);
          }
          this.dataContainers.pop();
          (<AbstractDataContainer>compRef.instance).dataStored = this.dataLines[this.dataLines.length - 1];
          (<AbstractDataContainer>compRef.instance).data['fieldValueModifiedSub'].unsubscribe();
          (<AbstractDataContainer>compRef.instance).fieldValueModified.subscribe((dataStored) => {
            const item = this.specificData.items.find((specificItem) => {
              return metaData.type === CustomDesignItemType.FIELD
                ? metaData.columnName === specificItem.data.columnName
                : metaData.tableName === specificItem.name;
            });
            this.notifyFromDataChange(item, this.dataLines.indexOf(dataStored));
          });
          if (!this.dataContainersLine[this.dataLines.length - 1]) {
            this.dataContainersLine[this.dataLines.length - 1] = [];
          }
          this.dataContainersLine[this.dataLines.length - 1].push(<AbstractDataContainer>compRef.instance);

          // (<AbstractDataContainer>compRef.instance).setNewData(this.dataLines[this.dataLines.length - 1]);
        }
      });
    }
  }
  removeLine(lineDataStore: DataStore, index: number) {
    if (
      this.dataStore &&
      this.dataStore.data['DocStatus'] &&
      (this.dataStore.data['DocStatus'] !== 'CO' || this.dataStore.data['DocStatus'].id !== 'CO')
    ) {
      const id =
        lineDataStore.data['C_OrderLine_ID'] instanceof Object
          ? lineDataStore.data['C_OrderLine_ID'].id
          : lineDataStore.data['C_OrderLine_ID'];
      if (id) {
        const sub$ = this.store.deleteWindowSpecificData('C_OrderLine', lineDataStore, id).subscribe((result) => {
          this.lineMetaData.forEach((metaData) => {
            this[metaData.vcr].toArray()[index].clear();
          });
          for (let i = index + 1; i < this.dataLines.length; i++) {
            this.lineMetaData.forEach((metaData) => {
              const element = (<ViewContainerRef>this[metaData.vcr].toArray()[i]).detach();
              (<ViewContainerRef>this[metaData.vcr].toArray()[i - 1]).insert(element);
            });
          }
          this.dataLines.splice(index, 1);
          this.dataContainersLine.splice(index, 1);
          let grandTotal = 0;
          this.dataLines.forEach((line) => {
            grandTotal += line.data['LineNetAmt'];
          });
          this.store.syncDataChanges(this.dataStore, { GrandTotal: Math.round((grandTotal + Number.EPSILON) * 100) / 100 });
          sub$.unsubscribe();
        });
      } else {
        this.lineMetaData.forEach((metaData) => {
          this[metaData.vcr].toArray()[index].clear();
        });
        for (let i = index + 1; i < this.dataLines.length; i++) {
          this.lineMetaData.forEach((metaData) => {
            const element = (<ViewContainerRef>this[metaData.vcr].toArray()[i]).detach();
            (<ViewContainerRef>this[metaData.vcr].toArray()[i - 1]).insert(element);
          });
        }
        this.dataLines.splice(index, 1);
        this.dataContainersLine.splice(index, 1);
      }
    }
  }
  processOrder() {
    this.subscription?.unsubscribe();
    if (
      this.dataStore &&
      ((this.dataStore.data['DocStatus'] instanceof Object && this.dataStore.data['DocStatus'].id !== 'CO') ||
        this.dataStore.data['DocStatus'] === 'DR')
    ) {
      let data = {};
      /*On ajoute le contexte utilisateur */
      const userContext = this.connectorService.getIupicsUserContext();
      const keys = Object.keys(userContext);
      keys.forEach((key) => {
        data[key] = cloneDeep(userContext[key]);
      });
      /*on merge le contexte avec le store du composant concerné et le contexte utilisateur */
      if (this.dataStore && this.dataStore.data) {
        data = EditViewUtils.mergeCurrentDataDeepCopy(data, this.dataStore.data);
      }
      this.updateLoading(true);
      this.subscription = this.workflowService
        .runWF({
          record_id: String(this.dataStore.data['C_Order_ID'].id),
          windowCtx: data,
          action: 'CO',
          table_id: '259',
          ad_process_id: '104'
        })
        .subscribe((response) => {
          this.updateLoading(false);
          if (response) {
            if (response.Success === true) {
              this.dataStore.data['DocStatus'] = response.DocStatus;
              if (response.DocStatus && response.DocStatus.id === 'CO') {
                this.docStatus = this.translateService.instant('specificWindow.quickOrder.processed');
                this.printPOS(this.dataStore.data['C_Order_ID'].id);
                this.resetOrder();
              } else {
                this.docStatus = this.translateService.instant('specificWindow.quickOrder.inProgress');
              }
            } else {
              this.messageManager.newMessage(
                new IupicsMessage(this.translateService.instant('generic.warning'), response.Message)
              );
            }
          }
        });
    }
  }

  resetOrder() {
    this.dataLines.forEach((dataLine, index) => {
      this.lineMetaData.forEach((metaData) => {
        this[metaData.vcr].toArray()[index].clear();
      });
    });
    this.dataLines = [];
    this.dataContainersLine = [];
    this.dataStore.dataChange.unsubscribe();
    this.dataStore = this.store.newSpecificWindowData(this.formId);
    this.afterNewSpecificWindowData();
    this.dataStore.dataChange.subscribe((dataChanged) => {
      Object.keys(dataChanged.dataModified).forEach((columnName) => {
        this.notifyFromDataChange({ data: { columnName: columnName } });
      });
    });
    this.dataContainers.forEach((dataContainer) => dataContainer.setNewData(this.dataStore));
    this.docStatus = this.translateService.instant('specificWindow.quickOrder.new');
  }

  protected afterNewSpecificWindowData() {
    this.dataStore.data['IsSelfService'] = 'N';
    this.dataStore.data['PriorityRule'] = '5';
    this.dataStore.data['IsDelivered'] = 'N';
    this.dataStore.data['FreightCostRule'] = 'I';
    this.dataStore.data['TotalLines'] = 0;
    this.dataStore.data['GrandTotal'] = 0;
    this.dataStore.data['DocStatus'] = 'DR';
    this.dataStore.data['DocAction'] = 'CO';
    this.dataStore.data['IsAuthorizeProject'] = 'N';
  }

  cancelOrder() {
    this.subscription?.unsubscribe();
    if (this.dataStore?.data?.DocStatus?.id === 'DR' || this.dataStore?.data?.DocStatus === 'DR') {
      let windowCtx = cloneDeep(this.connectorService.getIupicsUserContext());
      if (this.dataStore?.data) {
        windowCtx = EditViewUtils.mergeCurrentDataDeepCopy(windowCtx, this.dataStore.data);
      }
      this.updateLoading(true);
      this.subscription = this.workflowService
        .runWF({
          record_id: String(this.dataStore.data.C_Order_ID.id),
          windowCtx,
          action: 'VO',
          table_id: '259',
          ad_process_id: '104'
        })
        .subscribe((response) => {
          this.updateLoading(false);
          if (response) {
            if (response.Success === true) {
              this.dataStore.data['DocStatus'] = response.DocStatus;
              if (response.DocStatus && response.DocStatus.id === 'VO') {
                this.docStatus = this.translateService.instant('specificWindow.quickOrder.canceled');
              } else {
                this.docStatus = this.translateService.instant('specificWindow.quickOrder.inProgress');
              }
              this.resetOrder();
            } else {
              this.messageManager.newMessage(
                new IupicsMessage(this.translateService.instant('generic.warning'), response.Message)
              );
            }
          }
        });
    }
  }

  openCalculateChangePopUp() {
    if (
      isNil(this.dataStore) ||
      isNil(this.dataStore.data) ||
      !isObject(this.dataStore?.data?.C_Order_ID) ||
      !has(this.dataStore?.data?.C_Order_ID, 'id')
    ) {
      return;
    }
    this.popUpData = {
      toBePaid: this.dataStore?.data?.GrandTotal,
      customerIn: 0,
      changeOut: 0
    };
    this.messageService.add({
      key: this.popUpKey,
      sticky: true,
      closable: false
    });
  }

  calculeChange(_customerIn: number, changeOutInput: InputNumberUiComponent) {
    if (isNil(this.popUpData)) {
      return;
    }
    const { toBePaid } = this.popUpData;
    let { customerIn } = this.popUpData;
    customerIn = _customerIn;
    const changeOut = (toBePaid - _customerIn).toFixed(2);

    this.popUpData = { toBePaid, customerIn, changeOut };

    changeOutInput.fieldValue = this.popUpData.changeOut;
  }

  closeCalculateChangePopUp() {
    this.messageService.clear(this.popUpKey);
    this.popUpData = undefined;
  }

  private refreshOrder() {
    this.isrefreshing = true; // #147916
    this.subscription?.unsubscribe();
    const order_id =
      this.dataStore.data['C_Order_ID'] instanceof Object
        ? this.dataStore.data['C_Order_ID'].id
        : this.dataStore.data['C_Order_ID'];
    if (isNil(order_id)) {
      this.isrefreshing = false; // #147916
      return;
    }
    const s = this.store
      .getWindowSpecificData('C_Order', this.dataStore, order_id)
      .pipe(
        switchMap((data) => {
          this.docStatus =
            (this.dataStore.data['DocStatus'].id || this.dataStore.data['DocStatus']) === 'DR'
              ? this.translateService.instant('specificWindow.quickOrder.draft')
              : (this.dataStore.data['DocStatus'].id || this.dataStore.data['DocStatus']) === 'CO'
                ? this.translateService.instant('specificWindow.quickOrder.processed')
                : this.dataStore.data['DocStatus'].id || this.dataStore.data['DocStatus'];
          return this.store.getDataGrid(getLineIdsRequest(order_id, this.connectorService.getIupicsDefaultLanguage().iso_code));
        })
      )
      .subscribe((response) => {
        this.dataLines.forEach((_, index) => {
          this.lineMetaData.forEach((metaData) => {
            this[metaData.vcr].toArray()[index].clear();
          });
        });
        const oldDataLines = cloneDeep(this.dataLines);
        this.dataLines = [];
        if (response.data.length) {
          for (let i = 0; i < response.data.length; i++) {
            this.newLine(false, oldDataLines[i]);
            this.store.syncDataChanges(this.dataLines[i], response.data[i]);
          }
        } else {
          this.newLine(true);
        }
        this.isrefreshing = false; // #147916
      });
  }

  printPOS(record_id: number): void {
    const sessionId = this.connectorService.getIupicsUserAccount().session_id;
    const channel_id = shajs('sha256')
      .update(sessionId + '_' + 143 + '_' + moment().valueOf())
      .digest('hex');
    const paramsMap: ProcessParams = {
      ad_process_id: 110,
      ad_tab_id: 186,
      className: undefined,
      record_id,
      tableName: 'C_Order',
      tables: undefined,
      params: {
        query: `${record_id}`,
        FileFormat: 'PDF',
        AD_PrintFormat_ID: 0,
        channel_id
      },
      windowCtx: cloneDeep(this.dataStore.data)
    };
    this.subscriptions.push(this.uiCreator.executeProcess(paramsMap).subscribe());
  }
  getNullValueInData(data: any, columnNames: string[]) {
    let nullValue = null;
    columnNames.forEach((columnName) => {
      const value = data[columnName];
      if (
        value == null ||
        value == undefined ||
        value == '' ||
        (value.trim && value.trim() == '') ||
        (columnName === 'AD_Org_ID' && value.id <= 0)
      ) {
        nullValue = columnName;
        return;
      }
    });
    return nullValue;
  }
}

function getLineIdsRequest(order_id: number, ad_language: string): DataStoreRequest {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_OrderLine',
      ad_language,
      filterModel: {
        C_Order_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [order_id]
        }
      }
    }
  };
}
